import React from "react";
import bioinfo from "../data/bioInfo_Data";
import { nanoid } from "nanoid";

function BioInfo() {
  return (
    <div className='bioBox'>
      {Object.keys(bioinfo).map(theKey => (
        <div className='bioFact' key={nanoid()}>
          <span className='bioKey'>{theKey} </span>
          <span className='bioValue'> {bioinfo[theKey]} </span>
        </div>
      ))}
    </div>
  );
}

export default BioInfo;
