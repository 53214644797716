import React from "react";

import SlideShow from "../../components/SlideShow";

import miscCollection from "../../data/Paintings/misc_Data";
import NavSpacer from "../../components/NavSpacer";
import BackButton from "../../components/BackButton";
import { Helmet } from "react-helmet-async";

function Misc() {
  return (
    <div>
      <Helmet>
        <title>Tyrone Layne | Miscellaneous</title>
        <meta property='og.image' content={miscCollection[0].image} />
        <link rel='canonical' href='http://tyronelayne.com/misc' />
      </Helmet>
      <NavSpacer />
      <SlideShow
        slides={miscCollection}
        slideShowName='misc'
        startingIndex={0}
        scrollOffset={-40}
      />
      <BackButton />
    </div>
  );
}

export default Misc;
