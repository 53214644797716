import React from "react";
import BackButton from "../../components/BackButton";
import NavSpacer from "../../components/NavSpacer";
import SlideShow from "../../components/SlideShow";
import { Helmet } from "react-helmet-async";

import peopleScapesOldCollection from "./../../data/Paintings/PeoplescapesOld_Data";

function PeoplescapesOld() {
  return (
    <div>
      <Helmet>
        <title>TL | Peoplescapes 2007 - 2017</title>
        <meta
          property='og.image'
          content={peopleScapesOldCollection[0].image}
        />
        <link rel='canonical' href='http://tyronelayne.com/peoplescapes-old' />
      </Helmet>
      <NavSpacer />
      <SlideShow
        slides={peopleScapesOldCollection}
        slideShowName='peoplescalesOld'
        startingIndex={0}
        scrollOffset={-40}
      />
      <BackButton />
    </div>
  );
}

export default PeoplescapesOld;
