import aucklandCity from "../../images/Paintings/Cityscapes/01_aucklandCity.jpg";
import bricklane from "../../images/Paintings/Cityscapes/02_bricklane.jpg";
import oxfordCircus from "../../images/Paintings/Cityscapes/03_oxfordCircus.jpg";
import debtCollectors from "../../images/Paintings/Cityscapes/04_debtCollectors.jpg";
import longerHotterSummer from "../../images/Paintings/Cityscapes/05_longerHotterSummer.jpg";
import davidMeetsHelen from "../../images/Paintings/Cityscapes/06_davidMeetsHelen.jpg";
import ptChev3 from "../../images/Paintings/Cityscapes/07_ptChev3.jpg";
import theEndofRecession from "../../images/Paintings/Cityscapes/08_theEndofRecession.jpg";
import aLongHotSummer from "../../images/Paintings/Cityscapes/09_aLongHotSummer.jpg";
import rockyHorrorShow from "../../images/Paintings/Cityscapes/10_rockyHorrorShow.jpg";
import berkeleySquare from "../../images/Paintings/Cityscapes/11_berkeleySquare.jpg";
import coventGarden from "../../images/Paintings/Cityscapes/12_coventGarden.jpg";
import nightIsYoung from "../../images/Paintings/Cityscapes/13_nightIsYoung.jpg";
import piccadilyCircus1961 from "../../images/Paintings/Cityscapes/14_piccadilyCircus1961.jpg";
import greenPark from "../../images/Paintings/Cityscapes/15_greenPark.jpg";
import flowerMarket from "../../images/Paintings/Cityscapes/16_flowerMarket.jpg";
import shootingStar from "../../images/Paintings/Cityscapes/17_shootingStar.jpg";
import fountainOfYouth from "../../images/Paintings/Cityscapes/18_fountainOfYouth.jpg";
import novemberNights from "../../images/Paintings/Cityscapes/19_novemberNights.jpg";
import avenueOfAmericas from "../../images/Paintings/Cityscapes/20_avenueOfAmericas.jpg";
import futureOpeningNight from "../../images/Paintings/Cityscapes/21_futureOpeningNight.jpg";
import incomingTide from "../../images/Paintings/Cityscapes/22_incomingTide.jpg";
import theBanker from "../../images/Paintings/Cityscapes/23_theBanker.jpg";
import jafa from "../../images/Paintings/Cityscapes/24_jafa.jpg";
import realGroovy from "../../images/Paintings/Cityscapes/25_realGroovy.jpg";
import intoxicatedCity from "../../images/Paintings/Cityscapes/26_intoxicatedCity.jpg";
import spiltCoke from "../../images/Paintings/Cityscapes/27_spiltCoke.jpg";
import pointChevIV from "../../images/Paintings/Cityscapes/28_pointChevIV.jpg";
import armedRobbers from "../../images/Paintings/Cityscapes/29_armedRobbers.jpg";
import bonfire from "../../images/Paintings/Cityscapes/30_bonfire.jpg";
import kRoad from "../../images/Paintings/Cityscapes/31_kRoad.jpg";
import labourDay from "../../images/Paintings/Cityscapes/32_labourDay.jpg";
import pursuitOfHappiness from "../../images/Paintings/Cityscapes/33_pursuitOfHappiness.jpg";
import thinkingOutLoud from "../../images/Paintings/Cityscapes/34_thinkingOutLoud.jpg";
import pointChev2 from "../../images/Paintings/Cityscapes/35_pointChev2.jpg";
import thePsychologist from "../../images/Paintings/Cityscapes/36_thePsychologist.jpg";
import theYear2064 from "../../images/Paintings/Cityscapes/37_theYear2064.jpg";
import camel from "../../images/Paintings/Cityscapes/38_camel.jpg";
import lion from "../../images/Paintings/Cityscapes/39_lion.jpg";
import swineFluBritomart from "../../images/Paintings/Cityscapes/40_swineFluBritomart.jpg";
import pointChev from "../../images/Paintings/Cityscapes/41_pointChev.jpg";
import happyAsLarry from "../../images/Paintings/Cityscapes/42_happyAsLarry.jpg";
import theImpressionists from "../../images/Paintings/Cityscapes/43_theImpressionists.jpg";
import palermoHollywood from "../../images/Paintings/Cityscapes/44_palermoHollywood.jpg";
import kiwisInPeru from "../../images/Paintings/Cityscapes/45_kiwisInPeru.jpg";
import mullins from "../../images/Paintings/Cityscapes/46_mullins.jpg";
import carlisleBay from "../../images/Paintings/Cityscapes/47_carlisleBay.jpg";

const cityscapesCollection = [
  {
    image: aucklandCity,
    title: "Auckland City",
    description: "Oil on canvas. 110cm x 140cm. 2011",
    alt: "Auckland City. Oil on canvas. 110cm x 140cm. 2011",
  },
  {
    image: bricklane,
    title: "Bricklane- London",
    description: "Oil on canvas. 27cm X 44cm. 2013.",
    alt: "Bricklane- London. Oil on canvas. 27cm X 44cm. 2013",
  },
  {
    image: oxfordCircus,
    title: "Oxford Circus- London",
    description: "Oil on canvas. 61cm x 76cm. 2013",
    alt: "Oxford Circus- London. Oil on canvas. 61cm x 76cm. 2013",
  },
  {
    image: debtCollectors,
    title: "Debt Collectors at Piccadilly Circus",
    description: "Oil on canvas. 120cm x 150cm. 2012",
    alt: "Debt Collectors at Piccadilly Circus. Oil on canvas. 120cm x 150cm. 2012",
  },
  {
    image: longerHotterSummer,
    title: "Longer Hotter Summer",
    description: "Oil on canvas. 115cm x 140cm. 2010",
    alt: "Longer Hotter Summer. Oil on canvas. 115cm x 140cm. 2010",
  },
  {
    image: davidMeetsHelen,
    title: "David meets Helen",
    description: "Oil on canvas. 130cm X 110cm. 2014",
    alt: "David meets Helen. Oil on canvas. 130cm X 110cm. 2014",
  },
  {
    image: ptChev3,
    title: "Point Chev III- Lazy Sunday Afternoon",
    description: "Oil on canvas. 100cm x 80cm. 2010",
    alt: "Point Chev III- Lazy Sunday Afternoon. Oil on canvas. 100cm x 80cm. 2010",
  },
  {
    image: theEndofRecession,
    title: "The End of the Recession",
    description: "Oil on canvas. 140cm x 180cm. 2009",
    alt: "The End of the Recession. Oil on canvas. 140cm x 180cm. 2009",
  },
  {
    image: aLongHotSummer,
    title: "A Long Hot Summer",
    description: "Oil on board. 120cm x 90cm. 2009",
    alt: "A Long Hot Summer. Oil on board. 120cm x 90cm. 2009",
  },
  {
    image: rockyHorrorShow,
    title: "Rocky Horror Show at The Civic",
    description: "Oil on canvas. 110cm x 140cm. 2011",
    alt: "Rocky Horror Show at The Civic. Oil on canvas. 110cm x 140cm. 2011",
  },
  {
    image: berkeleySquare,
    title: "Summer Lunch- Berkeley Square, Mayfair",
    description: "Oil on canvas. 40cm x 50cm. 2012",
    alt: "Summer Lunch- Berkeley Square- Mayfair. Oil on canvas. 40cm x 50cm. 2012",
  },
  {
    image: coventGarden,
    title: "Covent Garden",
    description: "Oil on canvas. 40cm x 50cm. 2012",
    alt: "Covent Garden. Oil on canvas. 40cm x 50cm. 2012",
  },
  {
    image: nightIsYoung,
    title: "The Night is Young- Oxford Circus, London",
    description: "Oil on canvas. 40cm x 50cm. 2012",
    alt: "The Night is Young- Oxford Circus, London. Oil on canvas. 40cm x 50cm. 2012",
  },
  {
    image: piccadilyCircus1961,
    title: "Piccadilly Circus 1961- London",
    description: "Oil on canvas. 61cm X 91cm. 2013",
    alt: "Piccadilly Circus 1961- London. Oil on canvas. 61cm X 91cm. 2013",
  },
  {
    image: greenPark,
    title: "July heat wave- Green Park",
    description: "Oil on canvas. 75cm x 100cm. 2013",
    alt: "July heat wave- Green Park. Oil on canvas. 75cm x 100cm. 2013",
  },
  {
    image: flowerMarket,
    title: "Approach to Columbia Road Flower Market- London",
    description: "Oil on canvas. 20cm x 30cm. 2013",
    alt: "Approach to Columbia Road Flower Market- London. Oil on canvas. 20cm x 30cm. 2013",
  },
  {
    image: shootingStar,
    title: "Shooting Star",
    description: "Oil on canvas. 115cm x 140cm. 2010",
    alt: "Shooting Star. Oil on canvas. 115cm x 140cm. 2010",
  },
  {
    image: fountainOfYouth,
    title: "Fountain of Youth",
    description: "Oil on canvas. 70cm x 89cm. 2011-2013",
    alt: "Fountain of Youth- Mission Bay. Oil on canvas. 70cm x 89cm. 2011-2013",
  },
  {
    image: novemberNights,
    title: "November Nights",
    description: "Oil on canvas. 80cm x 100cm. 2011",
    alt: "November Nights. Oil on canvas. 80cm x 100cm. 2011",
  },
  {
    image: avenueOfAmericas,
    title: "49th Street & Avenue of Americas",
    description: "Oil on canvas. 80cm x 100cm. 2011",
    alt: "49th Street & Avenue of Americas. Oil on canvas. 80cm x 100cm. 2011",
  },
  {
    image: futureOpeningNight,
    title: "Tyrone's Future opening night at Anton Kern Gallery, New York",
    description: "Oil on canvas. 50cm x 60cm. 2009",
    alt: "Tyrone's Future opening night at Anton Kern Gallery, New York. Oil on canvas. 50cm x 60cm. 2009",
  },
  {
    image: incomingTide,
    title: "Incoming Tide",
    description: "Oil on canvas. 180cm x 300cm. 2014",
    alt: "Incoming Tide. Oil on canvas. 180cm x 300cm. 2014",
  },
  {
    image: theBanker,
    title: "The Banker",
    description: "Oil on cardboard. 39cm x 48cm. 2010",
    alt: "The Banker. Oil on cardboard. 39cm x 48cm. 2010",
  },
  {
    image: jafa,
    title: "Jafa",
    description: "Oil on canvas. 130cm X 110cm. 2014",
    alt: "Jafa. Oil on canvas. 130cm X 110cm. 2014",
  },
  {
    image: realGroovy,
    title: "Real Groovy",
    description: "Oil on canvas. 38cm x 48cm. 2011",
    alt: "Real Groovy. Oil on canvas. 38cm x 48cm. 2011",
  },
  {
    image: intoxicatedCity,
    title: "Intoxicated City",
    description: "Oil on canvas. 110cm X 130cm. 2014",
    alt: "Intoxicated City. Oil on canvas. 110cm X 130cm. 2014",
  },
  {
    image: spiltCoke,
    title: "Spilt coke on queen street",
    description: "Oil on board. 40cm x 50cm. 2014",
    alt: "Spilt coke on queen street. Oil on board. 40cm x 50cm. 2014",
  },
  {
    image: pointChevIV,
    title: "Point Chevalier IV",
    description: "Oil on canvas. 210cm x 390cm. 2011",
    alt: "Point Chevalier IV. Oil on canvas. 210cm x 390cm. 2011",
  },
  {
    image: armedRobbers,
    title: "Armed Robbers",
    description: "Oil on canvas. 110cm X 130cm. 2014",
    alt: "Armed Robbers. Oil on canvas. 110cm X 130cm. 2014",
  },
  {
    image: bonfire,
    title: "The Bonfire",
    description: "Oil on canvas. 39cm x 48cm. 2011",
    alt: "The Bonfire. Oil on canvas. 39cm x 48cm. 2011",
  },
  {
    image: kRoad,
    title: "K Road",
    description: "Oil on board. 35cm X 45cm. 2014",
    alt: "K Road. Oil on board. 35cm X 45cm. 2014",
  },
  {
    image: labourDay,
    title: "Pre-Labour Day",
    description: "Oil on cardboard. 39cm x 48cm. 2010",
    alt: "Pre-Labour Day. Oil on cardboard. 39cm x 48cm. 2010",
  },
  {
    image: pursuitOfHappiness,
    title: "Pursuit of Happiness",
    description: "Oil on board. 45cm x 61cm. 2009",
    alt: "Pursuit of Hapiness. Oil on board. 45cm x 61cm. 2009",
  },
  {
    image: thinkingOutLoud,
    title: "Thinking out loud",
    description: "Oil on cardboard. 39cm x 48cm. 2010",
    alt: "Thinking out loud. Oil on cardboard. 39cm x 48cm. 2010",
  },
  {
    image: pointChev2,
    title: "Point Chev II",
    description: "Oil on cardboard. 39cm x 48cm. 2010",
    alt: "Point Chev II. Oil on cardboard. 39cm x 48cm. 2010",
  },
  {
    image: thePsychologist,
    title: "The Psychologist",
    description: "Oil on cardboard. 39cm x 48cm. 2010",
    alt: "The Psychologist. Oil on cardboard. 39cm x 48cm. 2010",
  },
  {
    image: theYear2064,
    title: "Auckland Summer, The Year 2064",
    description: "Oil on canvas. 22cm X 28cm. 2014",
    alt: "Auckland Summer- The Year 2064. Oil on canvas. 22cm X 28cm. 2014",
  },
  {
    image: camel,
    title: "Camel",
    description: "Oil on canvas. 100cm x 150cm. 2010",
    alt: "Camel. Oil on canvas. 100cm x 150cm. 2010",
  },
  {
    image: lion,
    title: "Lion",
    description: "Oil on canvas. 100cm x 150cm. 2010",
    alt: "Lion. Oil on canvas. 100cm x 150cm. 2010",
  },
  {
    image: swineFluBritomart,
    title: "Dawn of the swine flu at Britomart",
    description: "Oil on canvas. 50cm x 60cm. 2009",
    alt: "Dawn of the swine flu at Britomart. Oil on canvas. 50cm x 60cm. 2009",
  },
  {
    image: pointChev,
    title: "New Sand at Point Chevalier",
    description: "Oil on canvas. 50cm x 60cm. 2009",
    alt: "New Sand at Point Chevalier. Oil on canvas. 50cm x 60cm. 2009",
  },
  {
    image: happyAsLarry,
    title: "Happy as Larry",
    description: "Oil on canvas. 50cm x 60cm. 2009",
    alt: "Happy as Larry, Oil on canvas. 50cm x 60cm. 2009",
  },
  {
    image: theImpressionists,
    title: "Tyrone and the Impressionists",
    description: "Oil on canvas. 50cm x 60cm. 2009",
    alt: "Tyrone and the Impressionists. Oil on canvas. 50cm x 60cm. 2009",
  },
  {
    image: palermoHollywood,
    title: "Palermo Hollywood- Buenos Aires",
    description: "Oil on cardboard. 39cm x 48cm. 2010",
    alt: "Palermo Hollywood- Buenos Aires. Oil on cardboard. 39cm x 48cm. 2010",
  },
  {
    image: kiwisInPeru,
    title: "18 Kiwi's in Peru",
    description: "Oil on canvas. 140cm x 180cm. 2008-2009",
    alt: "18 Kiwi's in Peru. Oil on canvas. 140cm x 180cm. 2008-2009",
  },
  {
    image: mullins,
    title: "Afternoon sea bath at Mullins- Barbados",
    description: "Oil on canvas. 30cm x 40cm. 2012",
    alt: "Afternoon sea bath at Mullins- Barbados. Oil on canvas. 30cm x 40cm. 2012",
  },
  {
    image: carlisleBay,
    title: "Carlisle Bay- Barbados",
    description: "Oil on canvas. 30cm x 40cm. 2012",
    alt: "Carlisle Bay- Barbados. Oil on canvas. 30cm x 40cm. 2012",
  },
];

export default cityscapesCollection;
