import inflatableLips from "../../images/Paintings/Peoplescapes_2018-now/1_inflatableLips.jpg";
import strangeParadise4 from "../../images/Paintings/Peoplescapes_2018-now/79_strangeParadise4.jpg";
import chelseaNyc from "../../images/Paintings/Peoplescapes_2018-now/86_chelseaNYC.jpg" ;
import theOptimistII from "../../images/Paintings/Peoplescapes_2018-now/83_theOptimist2.jpg" ;
import soho2053 from "../../images/Paintings/Peoplescapes_2018-now/93_soho2053.jpg" ;
import thredbo4 from "../../images/Paintings/Peoplescapes_2018-now/90_thredbo4.jpg" ;
import newYorkersSpring from "../../images/Paintings/Peoplescapes_2018-now/96_newYorkersSpring.jpg" ;
import newYorkersSummer from "../../images/Paintings/Peoplescapes_2018-now/97_newYorkersSummer.jpg" ;
import newYorkersFall from "../../images/Paintings/Peoplescapes_2018-now/95_newYorkersFall.jpg" ;
import newYorkersWinter from "../../images/Paintings/Peoplescapes_2018-now/88_newYorkersWinter.jpg" ;
import redfern from "../../images/Paintings/Peoplescapes_2018-now/2_redfern.jpg";
import manly3 from "../../images/Paintings/Peoplescapes_2018-now/3_manly3.jpg";
import theInterview6 from "../../images/Paintings/Peoplescapes_2018-now/4_theInterview6.jpg";
import theInterviewDetail from "../../images/Paintings/Peoplescapes_2018-now/5_theInterviewDetail.jpg";
import campCove from "../../images/Paintings/Peoplescapes_2018-now/6_campCove.jpg";
import woollahra2 from "../../images/Paintings/Peoplescapes_2018-now/7_woollahra2.jpg";
import paddo from "../../images/Paintings/Peoplescapes_2018-now/8_paddo.jpg";
import october from "../../images/Paintings/Peoplescapes_2018-now/9_october.jpg";
import mtBuller from "../../images/Paintings/Peoplescapes_2018-now/10_mtBuller.jpg";
import justHangingAround from "../../images/Paintings/Peoplescapes_2018-now/82_justHangingAround.jpg";
import overcast from "../../images/Paintings/Peoplescapes_2018-now/11_overcast.jpg";
import springStreet from "../../images/Paintings/Peoplescapes_2018-now/81_springSt.jpg";
import jazzInPark2 from "../../images/Paintings/Peoplescapes_2018-now/13_jazzInPark2.jpg";
import strangeParadise3 from "../../images/Paintings/Peoplescapes_2018-now/14_strangeParadise3.jpg";
import theRestaurant from "../../images/Paintings/Peoplescapes_2018-now/15_theRestaurant.jpg";
import richmond from "../../images/Paintings/Peoplescapes_2018-now/16_richmond.jpg";
import thredbo from "../../images/Paintings/Peoplescapes_2018-now/17_thredbo.jpg";
import paris from "../../images/Paintings/Peoplescapes_2018-now/18_paris.jpg";
import pinkAfternoon from "../../images/Paintings/Peoplescapes_2018-now/19_pinkAfternoon.jpg";
import marbleArch from "../../images/Paintings/Peoplescapes_2018-now/20_marbleArch.jpg";
import hamsteadHeath from "../../images/Paintings/Peoplescapes_2018-now/21_hamsteadHeath.jpg";
import ottawa from "../../images/Paintings/Peoplescapes_2018-now/22_ottawa.jpg";
import bondiSummers7 from "../../images/Paintings/Peoplescapes_2018-now/23_bondiSummers7.jpg";
import aDayInAnnadale from "../../images/Paintings/Peoplescapes_2018-now/24_aDayInAnnadale.jpg";
import eastVillage from "../../images/Paintings/Peoplescapes_2018-now/25_eastVillage.jpg";
import newYorkJune from "../../images/Paintings/Peoplescapes_2018-now/91_juneNewYork.jpg";
import kuttiBeach2 from "../../images/Paintings/Peoplescapes_2018-now/26_kuttiBeach2.jpg";
import manly2 from "../../images/Paintings/Peoplescapes_2018-now/27_manly2.jpg";
import campCoveDetail from "../../images/Paintings/Peoplescapes_2018-now/28_campCoveDetail.jpg";
import surryHills from "../../images/Paintings/Peoplescapes_2018-now/29_surryHills.jpg";
import castingCall from "../../images/Paintings/Peoplescapes_2018-now/30_castingCall.jpg";
import protestsDowntown from "../../images/Paintings/Peoplescapes_2018-now/31_protestsDowntown.jpg";
import theFilmSet from "../../images/Paintings/Peoplescapes_2018-now/32_theFilmSet.jpg";
import moonlightBeach from "../../images/Paintings/Peoplescapes_2018-now/33_moonlightBeach.jpg";
import sydney4 from "../../images/Paintings/Peoplescapes_2018-now/34_sydney4.jpg";
import les from "../../images/Paintings/Peoplescapes_2018-now/35_les.jpg";
import castingCall2 from "../../images/Paintings/Peoplescapes_2018-now/36_castingCall2.jpg";
import zermatt from "../../images/Paintings/Peoplescapes_2018-now/37_zermatt.jpg";
import theInterview from "../../images/Paintings/Peoplescapes_2018-now/38_theInterview.jpg";
import theBusStop5 from "../../images/Paintings/Peoplescapes_2018-now/87_busStop5.jpg";
import burleighHeads from "../../images/Paintings/Peoplescapes_2018-now/84_burleighHeads.jpg";
import northLondon from "../../images/Paintings/Peoplescapes_2018-now/39_northLondon.jpg";
import jacksonSquare from "../../images/Paintings/Peoplescapes_2018-now/92_jacksonSquare.jpg";
import skiHevenly from "../../images/Paintings/Peoplescapes_2018-now/94_skiHevenly.jpg";
import summerHolidays from "../../images/Paintings/Peoplescapes_2018-now/40_summerHolidays.jpg";
import forFiveLA from "../../images/Paintings/Peoplescapes_2018-now/41_forFiveLA.jpeg";
import darlinghurst from "../../images/Paintings/Peoplescapes_2018-now/42_darlinghurst.jpg";
import cityOfAngels from "../../images/Paintings/Peoplescapes_2018-now/43_cityOfAngels.jpg";
import theBusStop2 from "../../images/Paintings/Peoplescapes_2018-now/44_theBusStop2.jpg";
import greenpoint from "../../images/Paintings/Peoplescapes_2018-now/45_greenpoint.jpg";
import princeSt from "../../images/Paintings/Peoplescapes_2018-now/46_princeSt.jpg";
import east69thSt from "../../images/Paintings/Peoplescapes_2018-now/47_east69thSt.jpg";
import bondSt from "../../images/Paintings/Peoplescapes_2018-now/48_bondSt.jpg";
import bondStDetail from "../../images/Paintings/Peoplescapes_2018-now/49_bondStDetail.jpg";
import greeneSt from "../../images/Paintings/Peoplescapes_2018-now/89_greeneSt.jpg";
import losAngeles from "../../images/Paintings/Peoplescapes_2018-now/50_losAngeles.jpg";
import pointKint from "../../images/Paintings/Peoplescapes_2018-now/51_pointKint.jpg";
import fiDi2 from "../../images/Paintings/Peoplescapes_2018-now/52_fiDi2.jpg";
import aDayinPaddo3 from "../../images/Paintings/Peoplescapes_2018-now/53_aDayinPaddo3.jpg";
import studio2019 from "../../images/Paintings/Peoplescapes_2018-now/54_studio2019.jpg";
import bondiIcebergs from "../../images/Paintings/Peoplescapes_2018-now/55_bondiIcebergs.jpg";
import sydney3 from "../../images/Paintings/Peoplescapes_2018-now/56_sydney3.jpg";
import cobbleHill from "../../images/Paintings/Peoplescapes_2018-now/80_cobbleHill.JPG";
import sandringhamBeach from "../../images/Paintings/Peoplescapes_2018-now/98_sandringhamBeach.jpg";
import tamarama from "../../images/Paintings/Peoplescapes_2018-now/57_tamarama.jpg";
import paddington6 from "../../images/Paintings/Peoplescapes_2018-now/58_paddington6.jpg";
import aDayInChipenndale from "../../images/Paintings/Peoplescapes_2018-now/59_aDayInChipenndale.jpg";
import snowDayWilliamsburg from "../../images/Paintings/Peoplescapes_2018-now/60_snowDayWilliamsburg.jpg";
import skiApres from "../../images/Paintings/Peoplescapes_2018-now/61_skiApres.jpg";
import sandyBay from "../../images/Paintings/Peoplescapes_2018-now/62_sandyBay.jpg";
import london from "../../images/Paintings/Peoplescapes_2018-now/63_london.jpg";
import toorak from "../../images/Paintings/Peoplescapes_2018-now/64_toorak.jpg";
import aDayInWoolahra from "../../images/Paintings/Peoplescapes_2018-now/65_aDayInWoolahra.jpg";
import paddington4 from "../../images/Paintings/Peoplescapes_2018-now/66_paddington4.jpg";
import madisonAve from "../../images/Paintings/Peoplescapes_2018-now/67_madisonAve.jpg";
import theOptimist from "../../images/Paintings/Peoplescapes_2018-now/68_theOptimist.jpg";
import broadway from "../../images/Paintings/Peoplescapes_2018-now/69_broadway.jpg";
import snowDayNYC from "../../images/Paintings/Peoplescapes_2018-now/70_snowDayNYC.jpg";
import bleeckerStStation from "../../images/Paintings/Peoplescapes_2018-now/71_bleeckerStStation.jpg";
import amsterdam from "../../images/Paintings/Peoplescapes_2018-now/72_amsterdam.jpg";
import paddington7 from "../../images/Paintings/Peoplescapes_2018-now/73_paddington7.jpg";
import betootaHeights from "../../images/Paintings/Peoplescapes_2018-now/74_betootaHeights.jpg";
import bronte from "../../images/Paintings/Peoplescapes_2018-now/75_bronte.jpg";
import washingtonDC from "../../images/Paintings/Peoplescapes_2018-now/76_washingtonDC.jpg";
import familyVacation3 from "../../images/Paintings/Peoplescapes_2018-now/77_familyVacation3.jpg";
import middlePark from "../../images/Paintings/Peoplescapes_2018-now/78_middlePark.jpg";
import prospectPark from "../../images/Paintings/Peoplescapes_2018-now/85_prospectPark.jpg";

const peopleScapesNewCollection = [
  {
    image: inflatableLips,
    title: "Inflatable Lips and a Popsicle",
    description: "Oil & acrylic on linen. 76cm x 76cm. 2022",
    alt: "Inflatable Lips and a Popsicle. Oil & acrylic on linen. 76cm x 76cm. 2022",
  },
  {
    image: strangeParadise4,
    title: "Strange Paradise IV",
    description: "Oil & acrylic on linen. 152cm x 152cm. 2022/2023",
    alt: "Strange Paradise IV. Oil & acrylic on linen. 152cm x 152cm. 2022/2023",
  },
  {
    image: chelseaNyc,
    title: "Chelsea NYC",
    description: "Oil & acrylic on linen. 101cm x 101cm. 2023",
    alt: "Chelsea NYC. Oil & acrylic on linen. 101cm x 101cm. 2023",
  },
  {
    image: theOptimistII,
    title: "The Optimist II",
    description: "Oil & acrylic on linen. 122cm x 101cm. 2023",
    alt: "The Optimist II. Oil & acrylic on linen. 122cm x 101cm. 2023",
  },
  {
    image: soho2053,
    title: "Soho 2053",
    description: "Oil & acrylic on linen. 152cm x 152cm. 2023",
    alt: "Soho 2053. Oil & acrylic on linen. 152cm x 152cm. 2023",
  },
  {
    image: thredbo4,
    title: "Thredbo IV",
    description: "Oil & acrylic on linen. 137cm x 152cm. 2023",
    alt: "Thredbo IV. Oil & acrylic on linen. 137cm x 152cm. 2023",
  },
  {
    image: newYorkersSpring,
    title: "New Yorkers- Spring",
    description: "Oil & acrylic on linen. 71cm x 71cm. 2023",
    alt: "New Yorkers- Spring. Oil & acrylic on linen. 71cm x 71cm. 2023",
  },
  {
    image: newYorkersSummer,
    title: "New Yorkers- Summer",
    description: "Oil & acrylic on linen. 152cm x 152cm. 2023",
    alt: "New Yorkers- Summer. Oil & acrylic on linen. 152cm x 152cm. 2023",
  },
  {
    image: newYorkersFall,
    title: "New Yorkers- Fall",
    description: "Oil & acrylic on linen. 71cm x 71cm. 2023",
    alt: "New Yorkers- Fall. Oil & acrylic on linen. 71cm x 71cm. 2023",
  },
  {
    image: newYorkersWinter,
    title: "New Yorkers- Winter",
    description: "Oil & acrylic on linen. 152cm x 152cm. 2023",
    alt: "New Yorkers- Winter. Oil & acrylic on linen. 152cm x 152cm. 2023",
  },
  {
    image: redfern,
    title: "East Redfern",
    description: "Oil & acrylic on linen. 61cm x 71cm. 2021",
    alt: "East Redfern. Oil & acrylic on linen. 61cm x 71cm. 2021",
  },
  {
    image: manly3,
    title: "Manly III",
    description: "Oil & Acrylic on linen. 137cm X 152cm. 2021",
    alt: "Manly III. Oil & Acrylic on linen. 137cm X 152cm. 2021",
  },
  {
    image: theInterview6,
    title: "The Interview VI",
    description: "Oil & Acrylic on linen. 84cm X 84cm. 2019",
    alt: "The Interview VI. Oil & Acrylic on linen. 84cm X 84cm. 2019",
  },
  {
    image: theInterviewDetail,
    title: "The Interview VI (detail)",
    description: "Oil & Acrylic on linen. 84cm X 84cm. 2019",
    alt: "(detail) The Interview VI. Oil & Acrylic on linen. 84cm X 84cm. 2019",
  },
  {
    image: campCove,
    title: "Camp Cove IV & IV(15 minutes later)",
    description: "Oil & acrylic on linen. 61cm x 71cm(each). 2021",
    alt: "Camp Cove IV. Oil & acrylic on linen. 61cm x 71cm. 2021",
  },
  {
    image: woollahra2,
    title: "A Day in Woollahra II",
    description: "Oil & acrylic on linen. 76cm x 76cm. 2022",
    alt: "A Day in Woollahra II. Oil & acrylic on linen. 76cm x 76cm. 2022",
  },
  {
    image: paddo,
    title: "Paddington V",
    description: "Oil & Acrylic on linen. 137cm X 152cm. 2020",
    alt: "Paddington V. Oil & Acrylic on linen. 137cm X 152cm. 2020",
  },
  {
    image: october,
    title: "October",
    description: "Oil & Acrylic on linen. 150cm X 150cm. 2022",
    alt: "October. Oil & Acrylic on linen. 150cm X 150cm. 2022",
  },
  {
    image: mtBuller,
    title: "Mt Buller",
    description: "Oil & Acrylic on linen. 91cm X 122cm. 2020",
    alt: "Mt Buller. Oil & Acrylic on linen. 91cm X 122cm. 2020",
  },
  {
    image: justHangingAround,
    title: "New Yorkers- Just Hanging Around",
    description: "Oil & acrylic on linen. 152cm x 152cm. 2023",
    alt: "New Yorkers- Just Hanging Around. Oil & acrylic on linen. 152cm x 152cm. 2023",
  },
  {
    image: overcast,
    title: "Overcast",
    description: "Oil & Acrylic on linen. 137cm X 152cm. 2022",
    alt: "Overcast. Oil & Acrylic on linen. 137cm X 152cm. 2022",
  },
  {
    image: springStreet,
    title: "Spring St",
    description: "Oil & Acrylic on linen. 41cm X 35.5cm. 2023",
    alt: "Spring St. Oil & Acrylic on linen. 41cm X 35.5cm. 2023",
  },
  {
    image: jazzInPark2,
    title: "Jazz in the Park II",
    description: "Oil & acrylic on linen. 61cm x 61cm. 2018",
    alt: "Jazz in the Park II. Oil & acrylic on linen. 61cm x 61cm. 2018",
  },
  {
    image: strangeParadise3,
    title: "Strange Paradise III",
    description: "Oil & Acrylic on linen. 150cm X 150cm. 2022",
    alt: "Strange Paradise III. Oil & Acrylic on linen. 150cm X 150cm. 2022",
  },
  {
    image: theRestaurant,
    title: "The Restaurant",
    description: "Oil & acrylic on linen. 61cm x 71cm. 2022",
    alt: "The Restaurant. Oil & acrylic on linen. 61cm x 71cm. 2022",
  },
  {
    image: richmond,
    title: "Richmond",
    description: "Oil & Acrylic on linen. 152cm X 152cm. 2021",
    alt: "Richmond. Oil & Acrylic on linen. 152cm X 152cm. 2021",
  },
  {
    image: thredbo,
    title: "Thredbo III",
    description: "Oil & acrylic on linen. 61cm x 61cm. 2022",
    alt: "Thredbo III. Oil & acrylic on linen. 61cm x 61cm. 2022",
  },
  {
    image: paris,
    title: "Paris",
    description: "Oil & Acrylic on linen. 51cm X 51cm. 2018",
    alt: "Paris. Oil & Acrylic on linen. 51cm X 51cm. 2018",
  },
  {
    image: pinkAfternoon,
    title: "Pink Afternoon IV- Bronte Beach",
    description: "Oil & Acrylic on linen. 56cm X 66cm. 2018",
    alt: "Pink Afternoon IV- Bronte Beach. Oil & Acrylic on linen. 56cm X 66cm. 2018",
  },
  {
    image: marbleArch,
    title: "Marble Arch",
    description: "Oil & Acrylic on canvas. 61cm X 61cm. 2018",
    alt: "Marble Arch. Oil & Acrylic on canvas. 61cm X 61cm. 2018",
  },
  {
    image: hamsteadHeath,
    title: "Hampstead Heath",
    description: "Oil & Acrylic on canvas. 61cm X 61cm. 2018",
    alt: "Hampstead Heath. Oil & Acrylic on canvas. 61cm X 61cm. 2018",
  },
  {
    image: ottawa,
    title: "Rideau Canal- Ottawa",
    description: "Oil & acrylic on linen. 137cm x 152cm. 2021",
    alt: "Rideau Canal- Ottawa. Oil & acrylic on linen. 137cm x 152cm. 2021",
  },
  {
    image: bondiSummers7,
    title: "Bondi Summers VII",
    description: "Oil & Acrylic on linen. 137cm X 152cm. 2020",
    alt: "Bondi Summers VII. Oil & Acrylic on linen. 137cm X 152cm. 2020",
  },
  {
    image: aDayInAnnadale,
    title: "A Day in Annandale",
    description: "Oil & Acrylic on linen. 137cm X 152cm. 2018",
    alt: "A Day in Annandale. Oil & Acrylic on linen. 137cm X 152cm. 2018",
  },
  {
    image: eastVillage,
    title: "Monday Afternoon- East Village",
    description: "Oil & Acrylic on canvas. 61cm X 61cm. 2018",
    alt: "Monday Afternoon- East Village. Oil & Acrylic on canvas. 61cm X 61cm. 2018",
  },
  {
    image: newYorkJune,
    title: "Summer Morning, New York",
    description: "Oil & acrylic on linen. 152cm x 152cm. 2023",
    alt: "Summer Morning, New York. Oil & acrylic on linen. 152cm x 152cm. 2023",
  },
  {
    image: kuttiBeach2,
    title: "Kutti Beach II",
    description: "Oil & acrylic on linen. 61cm x 71cm. 2021",
    alt: "Kutti Beach II. Oil & acrylic on linen. 61cm x 71cm. 2021",
  },
  {
    image: manly2,
    title: "Manly 2",
    description: "Oil & Acrylic on linen. 61cm X 61cm. 2019",
    alt: "Manly 2. Oil & Acrylic on linen. 61cm X 61cm. 2019",
  },
  {
    image: campCoveDetail,
    title: "Camp Cove III(Detail)",
    description: "Oil & Acrylic on linen. 30.5cm X 30.5cm. 2019",
    alt: "Camp Cove III(Detail). Oil & Acrylic on linen. 30.5cm X 30.5cm. 2019",
  },
  {
    image: surryHills,
    title: "Surry Hills",
    description: "Oil & acrylic on linen. 137cm x 152cm. 2021",
    alt: "Surry Hills. Oil & acrylic on linen. 137cm x 152cm. 2021",
  },
  {
    image: castingCall,
    title: "Casting Call",
    description: "Oil & Acrylic on canvas. 36cm X 41cm. 2018",
    alt: "Casting Call. Oil & Acrylic on canvas. 36cm X 41cm. 2018",
  },
  {
    image: protestsDowntown,
    title: "Clown on Wall Street",
    description: "Oil & acrylic on canvas. 61cm x 61cm. 2018",
    alt: "Clown on Wall Street. Oil & acrylic on canvas. 61cm x 61cm. 2018",
  },
  {
    image: theFilmSet,
    title: "The Film Set",
    description: "Oil & Acrylic on canvas. 41cm X 36cm. 2018",
    alt: "The Film Set. Oil & Acrylic on canvas. 41cm X 36cm. 2018",
  },
  {
    image: moonlightBeach,
    title: "Moonlight Beach & Cardiff State Beach",
    description: "Oil & Acrylic on linen. 91cm X 84cm. 2018",
    alt: "Moonlight Beach & Cardiff State Beach. Oil & Acrylic on linen. 91cm X 84cm. 2018",
  },
  {
    image: sydney4,
    title: "Sydney IV",
    description: "Oil & acrylic on linen. 71cm x 92cm. 2021",
    alt: "Sydney IV. Oil & acrylic on linen. 71cm x 92cm. 2021",
  },
  {
    image: les,
    title: "New York in June- LES",
    description: "Oil & Acrylic on linen. 137cm X 152cm. 2020",
    alt: "New York in June- LES. Oil & Acrylic on linen. 137cm X 152cm. 2020",
  },
  {
    image: castingCall2,
    title: "Casting Call II",
    description: "Oil & Acrylic on linen. 84cm X 84cm. 2019",
    alt: "Casting Call II. Oil & Acrylic on linen. 84cm X 84cm. 2019",
  },
  {
    image: zermatt,
    title: "Zermatt",
    description: "Oil & Acrylic on linen. 102cm X 168cm. 2019/2020",
    alt: "Zermatt. Oil & Acrylic on linen. 102cm X 168cm. 2019/2020",
  },
  {
    image: theInterview,
    title: "The Interview V",
    description: "Oil & acrylic on canvas. 61cm X 61cm. 2018",
    alt: "The Interview V. Oil & Acrylic on canvas. 61cm X 61cm. 2018",
  },
  {
    image: theBusStop5,
    title: "The Bus Stop V: La Nina Summer",
    description: "Oil & acrylic on linen. 152cm X 183cm. 2023",
    alt: "The Bus Stop V: La Nina Summer. Oil & acrylic on linen. 152cm X 183cm. 2023",
  },
  {
    image: burleighHeads,
    title: "Burleigh Heads",
    description: "Oil & acrylic on linen. 76cm X 76cm. 2023",
    alt: "Burleigh Heads. Oil & acrylic on linen. 76cm X 76cm. 2023",
  },
  {
    image: northLondon,
    title: "Primrose Hill- London",
    description: "Oil & acrylic on linen. 71cm X 91cm. 2018",
    alt: "Primrose Hill- London. Oil & Acrylic on linen. 71cm X 91cm. 2018",
  },
  {
    image: jacksonSquare,
    title: "Jackson Square, San Francisco",
    description: "Oil & acrylic on linen. 91cm X 86cm. 2023",
    alt: "Jackson Square, San Francisco. Oil & acrylic on linen. 91cm X 86cm. 2023",
  },
  {
    image: skiHevenly,
    title: "Ski Heavenly",
    description: "Oil & acrylic on linen. 91cm X 86cm. 2023",
    alt: "Ski Heavenly. Oil & acrylic on linen. 91cm X 86cm. 2023",
  },
  {
    image: summerHolidays,
    title: "Summer Holidays III",
    description: "Oil & acrylic on linen. 91cm x 122cm. 2018",
    alt: "Summer Holidays III. Oil & acrylic on linen. 91cm x 122cm. 2018",
  },
  {
    image: forFiveLA,
    title: "ForFive CoffeeShop",
    description: "333 S Grand Ave. Downtown Los Angeles. 2019",
    alt: "ForFive CoffeeShop- 333 S Grand Ave. Downtown Los Angeles. 2019",
  },
  {
    image: darlinghurst,
    title: "Darlinghurst",
    description: "Oil & Acrylic on linen. 102cm X 122cm. 2020",
    alt: "Darlinghurst. Oil & Acrylic on linen. 102cm X 122cm. 2020",
  },
  {
    image: cityOfAngels,
    title: "City of Angels",
    description: "Oil & acrylic on linen. 150cm x 150cm. 2022",
    alt: "City of Angels. Oil & acrylic on linen. 150cm x 150cm. 2022",
  },
  {
    image: theBusStop2,
    title: "The Bus Stop II",
    description: "Oil & acrylic on canvas. 61cm x 61cm. 2018",
    alt: "The Bus Stop II. Oil & acrylic on canvas. 61cm x 61cm. 2018",
  },
  {
    image: greenpoint,
    title: "Greenpoint-Brooklyn & Greenwich Village-New York",
    description: "Each- Oil & acrylic on linen. 30.5cm x 30.5cm. 2020",
    alt: "Greenpoint-Brooklyn & Greenwich Village-New York",
  },
  {
    image: princeSt,
    title: "Prince St",
    description: "Oil & Acrylic on linen. 22.5cm X 20cm. 2018",
    alt: "Prince St. Oil & Acrylic on linen. 22.5cm X 20cm. 2018",
  },
  {
    image: east69thSt,
    title: "East 69th St",
    description: "Oil & Acrylic on linen. 152cm X 137cm. 2019",
    alt: "East 69th St. Oil & Acrylic on linen. 152cm X 137cm. 2019",
  },
  {
    image: bondSt,
    title: "Bond St",
    description: "Oil & acrylic on linen. 152cm x 137cm. 2019",
    alt: "Bond St. Oil & acrylic on linen. 152cm x 137cm. 2019",
  },
  {
    image: bondStDetail,
    title: "Bond St (Detail)",
    description: "Oil & acrylic on linen. 152cm x 137cm. 2019",
    alt: "Bond St (Detail). Oil & acrylic on linen. 152cm x 137cm. 2019",
  },
   {
    image: greeneSt,
    title: "Greene St",
    description: "Oil & acrylic on linen. 41cm x 36cm. 2023",
    alt: "Greene St. Oil & acrylic on linen. 41cm x 36cm. 2023",
  },
  {
    image: losAngeles,
    title: "West Hollywood & Downtown LA",
    description: "(Each) Oil & Acrylic on linen. 84cm X 84cm. 2018",
    alt: "West Hollywood & Downtown LA",
  },
  {
    image: pointKint,
    title: "Point Roadkight Beach",
    description: "Oil & Acrylic on linen. 137cm X 152cm. 2019",
    alt: "Point Roadkight Beach. Oil & Acrylic on linen. 137cm X 152cm. 2019",
  },
  {
    image: fiDi2,
    title: "Financial District NYC- Spring",
    description: "Oil & Acrylic on linen. 84cm X 84cm. 2018",
    alt: "Financial District NYC- Spring. Oil & Acrylic on linen. (each) 84cm X 84cm. 2018",
  },
  {
    image: aDayinPaddo3,
    title: "A Day in Paddo III",
    description: "Oil & acrylic on linen. 61cm x 61cm. 2022",
    alt: "A Day in Paddo III. Oil & acrylic on linen. 61cm x 61cm. 2022",
  },
  {
    image: studio2019,
    title: "Studio 2019",
    description: "Los Angeles paintings",
    alt: "Studio 2019",
  },
  {
    image: bondiIcebergs,
    title: "Bondi Icebergs",
    description: "Oil & Acrylic on linen. 30.5cm X 30.5cm. 2020",
    alt: "Bondi Icebergs. Oil & Acrylic on linen. 30.5cm X 30.5cm. 2020",
  },
  {
    image: sydney3,
    title: "Sydney III",
    description: "Oil & acrylic on linen. 137cm X 152cm. 2020",
    alt: "Sydney III. Oil & acrylic on linen. 137cm X 152cm. 2020",
  },
  {
    image: cobbleHill,
    title: "Cobble Hill- Brooklyn",
    description: "Oil & acrylic on linen. 71cm X 71cm. 2023",
    alt: "Cobble Hill- Brooklyn. Oil & acrylic on linen. 71cm X 71cm. 2023",
  },
  {
    image: sandringhamBeach,
    title: "Sandringham Beach",
    description: "Oil & acrylic on linen. 61cm X 101cm. 2023",
    alt: "Sandringham Beach. Oil & acrylic on linen. 61cm X 101cm. 2023",
  },
  {
    image: tamarama,
    title: "Tamarama",
    description: "Oil & acrylic on linen. 66cm x 56cm. 2018",
    alt: "Tamarama. Oil & acrylic on linen. 66cm x 56cm. 2018",
  },
  {
    image: paddington6,
    title: "Paddington VI",
    description: "Oil & Acrylic on linen. 84cm x 84cm. 2020",
    alt: "Paddington VI. Oil & Acrylic on linen. 84cm x 84cm. 2020",
  },
  {
    image: aDayInChipenndale,
    title: "A Day in Chippendale",
    description: "Oil & Acrylic on canvas. 84cm X 84cm. 2020",
    alt: "A Day in Chippendale. Oil & Acrylic on canvas. 84cm X 84cm. 2020",
  },
  {
    image: snowDayWilliamsburg,
    title: "Snow Day in Williamsburg",
    description: "Oil & Acrylic on canvas. 84cm X 84cm. 2020",
    alt: "Snow Day in Williamsburg. Oil & Acrylic on canvas. 84cm X 84cm. 2020",
  },
  {
    image: skiApres,
    title: "Après-ski",
    description: "Oil & acrylic on linen. 30.5cm x 30.5cm. 2022",
    alt: "Après-ski. Oil & acrylic on linen. 30.5cm x 30.5cm. 2022",
  },
  {
    image: sandyBay,
    title: "Sandy Bay",
    description: "Oil & Acrylic on linen. 30.5cm X 30.5cm. 2020",
    alt: "Sandy Bay. Oil & Acrylic on linen. 30.5cm X 30.5cm. 2020",
  },
  {
    image: london,
    title: "North London- Angel to Highbury",
    description: "Oil & Acrylic on linen. 91cm X 122cm. 2019",
    alt: "North London- Angel to Highbury. Oil & Acrylic on linen. 91cm X 122cm. 2019",
  },
  {
    image: toorak,
    title: "Toorak",
    description: "Oil & Acrylic on linen. 152cm X 152cm. 2021",
    alt: "Toorak. Oil & Acrylic on linen. 152cm X 152cm. 2021",
  },
  {
    image: aDayInWoolahra,
    title: "A Day in Woollahra",
    description:
      "Oil & acrylic on linen. (Diptych) 56cm x 61cm each canvas. 2020",
    alt: "A Day in Woollahra. Oil & acrylic on linen. (Diptych) 56cm x 61cm each canvas. 2020",
  },
  {
    image: paddington4,
    title: "Paddington IV",
    description: "Oil & acrylic on linen. 91cm x 112cm. 2018",
    alt: "Paddington IV. Oil & acrylic on linen. 91cm x 112cm. 2018",
  },
  {
    image: madisonAve,
    title: "Madison Avenue- Upper East Side",
    description: "Oil & Acrylic on linen. 84cm x 84cm. 2020",
    alt: "Madison Avenue, Upper East Side. Oil & Acrylic on linen. 84cm x 84cm. 2020",
  },
  {
    image: theOptimist,
    title: "The Optimist",
    description: "Oil & Acrylic on linen. 91cm X 102cm. 2020",
    alt: "The Optimist. Oil & Acrylic on linen. 91cm X 102cm. 2020",
  },
  {
    image: broadway,
    title: "Broadway",
    description: "Oil & acrylic on linen. 51cm x 46cm. 2022",
    alt: "Broadway. Oil & acrylic on linen. 51cm x 46cm. 2022",
  },
  {
    image: snowDayNYC,
    title: "Snow Day in Soho",
    description: "Oil & acrylic on canvas. 137cm X 152cm. 2018",
    alt: "Snow Day in Soho. Oil & acrylic on canvas. 137cm X 152cm. 2018",
  },
  {
    image: bleeckerStStation,
    title: "Bleecker Street Station",
    description: "Oil & acrylic on board. 45cm x 55cm. 2018",
    alt: "Bleecker Street Station. Oil & acrylic on board. 45cm x 55cm. 2018",
  },
  {
    image: amsterdam,
    title: "Oud-West Amsterdam",
    description: "Oil & acrylic on linen. 38cm X 41cm. 2019",
    alt: "Oud-West Amsterdam. Oil & acrylic on linen. 38cm X 41cm. 2019",
  },
  {
    image: paddington7,
    title: "Paddington VII",
    description: "Oil & acrylic on linen. 137cm X 152cm. 2021",
    alt: "Paddington VII. Oil & acrylic on linen. 137cm X 152cm. 2021",
  },
  {
    image: betootaHeights,
    title: "Betoota Heights",
    description: "Oil & acrylic on linen. 61cm x 71cm. 2022",
    alt: "Betoota Heights. Oil & acrylic on linen. 61cm x 71cm. 2022",
  },
  {
    image: bronte,
    title: "Twins at Bronte Beach II",
    description: "Oil & acrylic on linen. 84cm X 84cm. 2020",
    alt: "Twins at Bronte Beach II. Oil & acrylic on linen. 84cm X 84cm. 2020",
  },
  {
    image: washingtonDC,
    title: "DC/Virgina",
    description: "Oil & acrylic on linen. 137cm X 152cm. 2019",
    alt: "DC/Virgina. Oil & acrylic on linen. 137cm X 152cm. 2019",
  },
  {
    image: familyVacation3,
    title: "Family Vacation III",
    description: "Oil & acrylic on linen. 92cm x 84cm. 2017-2019",
    alt: "Family Vacation III. Oil & acrylic on linen. 92cm x 84cm. 2017-2019",
  },
  {
    image: middlePark,
    title: "Middle Park- Melbourne",
    description: "Oil & acrylic on linen. 71cm x 71cm. 2017-2018",
    alt: "Middle Park- Melbourne. Oil & acrylic on linen. 71cm x 71cm. 2017-2018",
  },
   {
    image: prospectPark,
    title: "Prospect Avenue- Brooklyn",
    description: "Oil & acrylic on linen. 71cm x 71cm. 2023",
    alt: "Prospect Avenue- Brooklyn. Oil & acrylic on linen. 71cm x 71cm. 2023",
  },
];

export default peopleScapesNewCollection;
