import React from "react";
import imageScroller from "../utils/imageScroller";
import renderSoldLabel from "./SoldText";

const renderLabel = (label, sold, labelLine2) => {
  if (label && labelLine2) {
    return (
      <div className='imgLinkLabelBox imageSpace'>
        <div className='imageLabel'>
          {label} {renderSoldLabel(sold)}
        </div>
        <div className='imageLabel goGreyNow'>{labelLine2}</div>
      </div>
    );
  } else if (label) {
    return (
      <div className='imageLabel imageSpace'>
        {label} {renderSoldLabel(sold)}
      </div>
    );
  }
};

const renderCrossOut = sold => {
  if (sold) {
    return "normalImageBox"; // crossOut";
  } else {
    return "normalImageBox";
  }
};

const soldOpacity = sold => {
  if (sold) {
    return " soldImage";
  }
};

const renderCross = sold => {
  if (sold) {
    return <div className='davidCross' />;
  }
};

const Image = ({ source, description, label, sold, cssClass, labelLine2 }) => {
  return (
    <div className='normalImageContainer'>
      <div className={renderCrossOut(sold)}>
        <img
          src={source}
          alt={description}
          id='normalImage'
          className={cssClass + soldOpacity(sold)}
          onClick={() => imageScroller(cssClass)}
        />
        {renderCross(sold)}
      </div>
      {renderLabel(label, sold, labelLine2)}
    </div>
  );
};

export default Image;
