import i1 from "../../images/shop/Stockroom/1_theOptimistII.JPG";
import i2 from "../../images/shop/Stockroom/2_theOptimistII.jpg";
import i3 from "../../images/shop/Stockroom/3_theOptimistII.JPG";
import i4 from "../../images/shop/Stockroom/4_theOptimistII.JPG";
import i5 from "../../images/shop/Stockroom/5_theOptimistII.JPG";
import i6 from "../../images/shop/Stockroom/6_theOptimistII.JPG";
import i7 from "../../images/shop/Stockroom/7_theOptimistII.JPG";
import i8 from "../../images/shop/Stockroom/8_theOptimistII.JPG";

const theOptimist2OriginalCollection = [
  { image: i1, alt: "The Optimist II" },
  { image: i2, alt: "The Optimist II" },
  { image: i3, alt: "The Optimist II" },
  { image: i4, alt: "The Optimist II" },
  { image: i5, alt: "The Optimist II" },
  { image: i6, alt: "The Optimist II" },
  { image: i7, alt: "The Optimist II" },
  { image: i8, alt: "The Optimist II" },
];

export default theOptimist2OriginalCollection;
