import React from "react";
import imageScroller from "../utils/imageScroller";

const renderLabel = (label, sold) => {
  if (label && sold) {
    return (
      <div className='imageLabel'>
        {label} <div className='sold'>-SOLD</div>
      </div>
    );
  } else if (label) {
    return <div className='imageLabel'>{label}</div>;
  }
};

const ImageFormatted = ({
  source,
  description,
  label,
  sold,
  cssClass,
  topImage,
}) => {
  const isItTop = () => {
    if (topImage) {
      return "topImage";
    } else {
      return "";
    }
  };

  return (
    <div className={`squeezeImageContainer ${isItTop()}`}>
      <div className='squeezeImageBox'>
        <img
          src={source}
          alt={description}
          id='squeezeImage'
          className={cssClass}
          onClick={() => imageScroller(cssClass)}
        />
        {renderLabel(label, sold)}
      </div>
    </div>
  );
};

export default ImageFormatted;
