import React from "react";
import BackButton from "../../components/BackButton";
import SlideShow from "../../components/SlideShow";
import { Helmet } from "react-helmet-async";
import abstractCollection from "../../data/Paintings/abstracts_Data.js";
// import imageScroller from "../../utils/imageScroller";
import NavSpacer from "./../../components/NavSpacer";

function Abstracts() {
  // useEffect(() => {
  //   imageScroller("App");
  // });
  return (
    <div>
      <Helmet>
        <title>Tyrone Layne | Abstracts</title>
        <meta property='og.image' content={abstractCollection[0].image} />
        <link rel='canonical' href='http://tyronelayne.com/abstracts' />
      </Helmet>
      <NavSpacer />
      <SlideShow
        slides={abstractCollection}
        slideShowName='abstracts'
        startingIndex={0}
        scrollOffset={-40}
      />
      <BackButton />
    </div>
  );
}

export default Abstracts;
