import React from "react";
import { useNavigate } from "react-router-dom";

function BackButton() {
  const navigate = useNavigate();

  const handleBackButton = () => {
    navigate(-1);
  };

  return (
    <div className='backButtonBox'>
      <button className='backButton' onClick={handleBackButton}>
        Go Back
      </button>
    </div>
  );
}

export default BackButton;
