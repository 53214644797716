import React from "react";
const Title = ({ subtitle }) => {
  return (
    <div className='titleContainer'>
      <div className='titleBox'>
        {/* <Link to='/'> */}
        <h1>TYRONE LAYNE</h1>
        {/* </Link> */}
        <h1 className='greyText'>{subtitle}</h1>
      </div>
    </div>
  );
};

export default Title;
