import i1 from "../../images/shop/Stockroom/1_parisOriginal.JPG";
import i2 from "../../images/shop/Stockroom/2_parisOriginal.JPG";
import i3 from "../../images/shop/Stockroom/3_parisOriginal.JPG";
import i4 from "../../images/shop/Stockroom/4_parisOriginal.JPG";
import i5 from "../../images/shop/Stockroom/5_parisOriginal.JPG";
import i6 from "../../images/shop/Stockroom/6_parisOriginal.JPG";
import i7 from "../../images/shop/Stockroom/7_parisOriginal.JPG";
import i8 from "../../images/shop/Stockroom/8_parisOriginal.JPG";
import i9 from "../../images/shop/Stockroom/9_parisOriginal.JPG";


const parisOriginalCollection = [
  { image: i1, alt: "Paris: A Moveable Feast" },
  { image: i2, alt: "Paris: A Moveable Feast" },
  { image: i3, alt: "Paris: A Moveable Feast" },
  { image: i4, alt: "Paris: A Moveable Feast" },
  { image: i5, alt: "Paris: A Moveable Feast" },
  { image: i6, alt: "Paris: A Moveable Feast" },
  { image: i7, alt: "Paris: A Moveable Feast" },
  { image: i8, alt: "Paris: A Moveable Feast" },
  { image: i9, alt: "Paris: A Moveable Feast" },
 ];

export default parisOriginalCollection;
