/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";

function FloatingSubtitle({ text, extraCSS, disappear }) {
  const [visibility, setVisibility] = useState(true);

  const handleScroll = () => {
    if (visibility && disappear) {
      if (window.scrollY > 30) {
        setVisibility(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", () => handleScroll());

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className='floatingSubtitleContainer'>
      <h1
        className={`subText ${extraCSS}`}
        style={{
          opacity: visibility ? 1 : 0,
          transition: "0.5s",
        }}
      >
        {text}
      </h1>
    </div>
  );
}

export default FloatingSubtitle;
