import i1 from "../../images/shop/Stockroom/1_betootaHeights.jpg";
import i2 from "../../images/shop/Stockroom/2_betootaHeights.jpg";
import i3 from "../../images/shop/Stockroom/3_betootaHeights.jpg";
import i4 from "../../images/shop/Stockroom/4_betootaHeights.jpg";

const betootaCollection = [
  { image: i1, alt: "Betoota Heights" },
  { image: i2, alt: "Betoota Heights" },
  { image: i3, alt: "Betoota Heights" },
  { image: i4, alt: "Betoota Heights" },
];

export default betootaCollection;
