import React, { useEffect } from "react";
import imageScroller from "./../../../utils/imageScroller";
import HeaderSpacer from "./../../../components/HeaderSpacer";
import theOptimist2OriginalCollection from "./../../../data/shop/theOptimist2Original_Data";
import BackButton from "./../../../components/BackButton";
import Square from "../../../components/Square";
import SquareSpacer from "../../../components/SquareSpacer";
import SlideShow from "./../../../components/SlideShow";
import { Helmet } from "react-helmet-async";



function TheOptimist2Original() {
  useEffect(() => {
    imageScroller("App");
  });

  return (
    <div>
      <Helmet>
        <title>TL | The Optimist II</title>
        <meta property='og.title' content='The Optimist II - 2023/2024' />
        <meta property='og.image' content={theOptimist2OriginalCollection[0].image} />
      </Helmet>
      <HeaderSpacer />
      <p>
        <strong>The Optimist II - 2023/2024 </strong>
        
      </p>
      <p>Oil & acrylic on linen</p>
      <p>122cm x 102cm (48" x 40")</p>
      <p>Italian linen stretched on aluminium stretcher bars ready to hang.</p>
      <p>
        <strong>$5,950AUD</strong>
      </p>
      <p>Free international shipping</p>
      <br></br>
      <p>"The Optimist II" stands as a quintessential example of a Tyrone Layne beach peoplescape. It was finished at the close of 2023, following a notably warm beginning to Spring and early Summer in Sydney. If you're interested in experiencing the painting firsthand in Sydney, feel free to <a href="mailto:tvlat@hotmail.com">email me</a> to schedule a studio visit. Alternatively, for those located elsewhere in the world, we can arrange a video call to explore the artwork together.</p>
      <SlideShow
        slides={theOptimist2OriginalCollection}
        slideShowName='optimist2Slides'
        startingIndex={0}
        scrollOffset={-20}
      />

      <Square
        thePrice={"$5,950.00"}
        theTitle={"The Optimist II - Oil & acrylic on linen"}
        theLink={"https://square.link/u/FrO4iguT?src=embed"}
      />
      <SquareSpacer />
      
      <BackButton />
    </div>
  );
}

export default TheOptimist2Original;
