import React, { useState, useEffect } from "react";
// import React from "react";
import BackButton from "../../components/BackButton";
import NavSpacer from "../../components/NavSpacer";
import SlideShow from "../../components/SlideShow";
import returnYear from "../../utils/GetYear";
import { Helmet } from "react-helmet-async";
import peopleScapesNewCollection from "./../../data/Paintings/PeoplescapesNew_Data";

function PeoplescapesNew() {
  const [thisYear, setThisYear] = useState("2023");

  useEffect(() => {
    setThisYear(returnYear());
  }, []);

  return (
    <div>
      <Helmet>
        <title>TL | Peoplescapes 2018 - {`${thisYear}`}</title>
        <link rel='canonical' href='http://tyronelayne.com/peoplescapes-new' />
        {/* <meta
          property='og.image'
          content={peopleScapesNewCollection[0].image}
        /> */}
      </Helmet>
      <NavSpacer />
      <SlideShow
        slides={peopleScapesNewCollection}
        slideShowName='peoplescapesNew'
        startingIndex={1}
        scrollOffset={-40}
      />
      <BackButton />
    </div>
  );
}

export default PeoplescapesNew;
