import i1 from "../../images/shop/24thprintParis/1_paris.jpg";
import i2 from "../../images/shop/24thprintParis/2_paris.jpg";
import i3 from "../../images/shop/24thprintParis/3_paris.jpg";
import i4 from "../../images/shop/24thprintParis/4_paris.jpg";
import i5 from "../../images/shop/24thprintParis/5_paris.jpg";
import i6 from "../../images/shop/24thprintParis/6_paris.jpg";
import i7 from "../../images/shop/24thprintParis/7_paris.jpg";

const parisCollection = [
  { image: i1, alt: "Paris: A Moveable Feast" },
  { image: i2, alt: "Paris: A Moveable Feast" },
  { image: i3, alt: "Paris: A Moveable Feast" },
  { image: i4, alt: "Paris: A Moveable Feast" },
  { image: i5, alt: "Paris: A Moveable Feast" },
  { image: i6, alt: "Paris: A Moveable Feast" },
  { image: i7, alt: "Paris: A Moveable Feast" },
];

export default parisCollection;
