import React, { useEffect } from "react";
import imageScroller from "./../../../utils/imageScroller";
import HeaderSpacer from "./../../../components/HeaderSpacer";
import betootaCollection from "./../../../data/shop/betoota_Data";
import BackButton from "./../../../components/BackButton";
// import Square from "../../../components/Square";
// import SquareSpacer from "../../../components/SquareSpacer";
import SlideShow from "./../../../components/SlideShow";
import renderSoldLabel from "./../../../components/SoldText";
import { Helmet } from "react-helmet-async";

function Betoota() {
  useEffect(() => {
    imageScroller("App");
  });

  return (
    <div>
      <Helmet>
        <title>TL | Betoota Heights</title>
        <meta property='og.title' content='Betoota Heights - 2022' />
        <meta property='og.image' content={betootaCollection[0].image} />
      </Helmet>
      <HeaderSpacer />
      <p>
        <strong>Betoota Heights - 2022 </strong>
        {renderSoldLabel(true)}
      </p>
      <p>Oil & acrylic on linen</p>
      <p>61cm x 71cm</p>
      <p>Stretched and ready to hang.</p>
      <p>
        <strong>$2,850AUD</strong>
      </p>
      <p>Free international shipping</p>
      <SlideShow
        slides={betootaCollection}
        slideShowName='betootaSlides'
        startingIndex={0}
        scrollOffset={-20}
      />

      {/* <Square
        thePrice={"$2,850.00"}
        theTitle={"Betoota Heights - Oil on canvas"}
        theLink={"https://square.link/u/RBxRS104?src=embed"}
      />
      <SquareSpacer />
       */}
      <BackButton />
    </div>
  );
}

export default Betoota;
