import React, { useEffect, useState } from "react";
import Slide from "./Slide";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import leftArrow from "../icons/caret-left-solid.svg";
import rightArrow from "../icons/caret-right-solid.svg";
import { scroller } from "react-scroll";
import { nanoid } from "nanoid";
// import Arrows from "./Arrows";

// import NavSpacer from "./NavSpacer";

function SlideShow({
  slides,
  slideShowName,
  slideHeight,
  scrollOffset,
  shopSlideShow,
}) {
  // const [centreMode, setCentreMode] = useState(true);
  const [slideWidth, setSlideWidth] = useState(60);

  const handleResize = () => {
    const width = window.innerWidth;

    if (width < 1045) {
      setSlideWidth(67);
    } else {
      setSlideWidth(60);
    }

    if (width < 815) {
      //      setCentreMode(false);
      setSlideWidth(100);
    } else {
      //    setCentreMode(true);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    handleResize();
  });

  const isItAShopSlideShow = () => {
    if (shopSlideShow) {
      return "shopSlideShow";
    } else {
      return "";
    }
  };

  const executeScroll = () => {
    scroller.scrollTo(slideShowName, {
      duration: 100,
      delay: 0,
      smooth: "linear",
      offset: scrollOffset,
    });
  };

  const handleClick = slideShowName => {
    executeScroll(slideShowName);
  };

  return (
    <React.Fragment>
      <div
        className={`slideShowContainer ${slideShowName} `}
        id={`${isItAShopSlideShow()}`}
      >
        <Carousel
          emulateTouch={true}
          showThumbs={false}
          // infiniteLoop={true}
          showStatus={false}
          showArrows={true}
          onSwipeStart={() => {
            handleClick(slideShowName);
          }}
          id='slideShow'
          className={`slideShow `}
          showIndicators={false}
          swipeScrollTolerance={30}
          centerSlidePercentage={slideWidth} //was 60
          selectedItem={0}
          preventMovementUntilSwipeScrollTolerance={true}
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev ? (
              <div
                className='arrowCursor arrow arrowLeft'
                onClick={() => {
                  onClickHandler();
                  handleClick();
                }}
              >
                <img className='arrowPic' src={leftArrow} alt={label} />
              </div>
            ) : null
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext ? (
              <div
                className='arrowCursor arrow arrowRight'
                onClick={() => {
                  onClickHandler();
                  handleClick();
                }}
              >
                <img src={rightArrow} className='arrowPic' alt={label} />
              </div>
            ) : null
          }
        >
          {slides.map(theSlide => (
            <Slide
              image={theSlide.image}
              title={theSlide.title}
              description={theSlide.description}
              alt={theSlide.alt}
              key={nanoid()}
              sold={theSlide.sold}
              price={theSlide.price}
              slideHeight={slideHeight}
              shopSlide={shopSlideShow}
            />
          ))}
        </Carousel>
      </div>
      {/* <NavSpacer /> */}
    </React.Fragment>
  );
}

export default SlideShow;
