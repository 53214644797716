import React from "react";
import Exhibition from "./Exhibition";
import { nanoid } from "nanoid";

function Exhibitions({ exhibitions }) {
  return (
    <div className='exhibitionContainer'>
      {exhibitions.map(theExhibition => (
        <Exhibition
          year={theExhibition.year}
          exhibition={theExhibition.exhibition}
          location={theExhibition.location}
          date={theExhibition.date}
          key={nanoid()}
          theLink={theExhibition.theLink}
          link={theExhibition.link}
          linkOut={theExhibition.linkOut}
        />
      ))}
    </div>
  );
}

export default Exhibitions;
