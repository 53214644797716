import React from "react";
import { Link } from "react-router-dom";
import HeaderSpacer from "../components/HeaderSpacer";
import { Helmet } from "react-helmet-async";

function Shop() {
  return (
    <div>
      <Helmet>
        <title>Tyrone Layne | Shop</title>
      </Helmet>
      <HeaderSpacer />
      <div className='shopLinkContainer'>
        <Link to='/prints' className='shopLinkBox printLink backgroundTint'>
          <div>Prints</div>
          <div>Limited Edition Prints</div>
        </Link>
        <Link
          to='/painting-shop'
          className='shopLinkBox paintingLink backgroundTint'
        >
          <div>Paintings</div>
          <div>Original Oil Paintings</div>
        </Link>
      </div>
    </div>
  );
}

export default Shop;
