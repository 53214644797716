import i1 from "../../images/shop/20thprintThredbo4/1_thredbo4.jpg";
import i2 from "../../images/shop/20thprintThredbo4/2_thredbo4.jpg";
import i3 from "../../images/shop/20thprintThredbo4/3_thredbo4.jpg";
import i4 from "../../images/shop/20thprintThredbo4/4_thredbo4.jpg";
import i5 from "../../images/shop/20thprintThredbo4/5_thredbo4.jpg";
import i6 from "../../images/shop/20thprintThredbo4/6_thredbo4.jpg";
import i7 from "../../images/shop/20thprintThredbo4/7_thredbo4.JPG";
import i8 from "../../images/shop/20thprintThredbo4/8_thredbo4.JPEG";

const thredbo4Col = [
  { image: i1, alt: "Thredbo IV" },
  { image: i2, alt: "Thredbo IV" },
  { image: i3, alt: "Thredbo IV" },
  { image: i4, alt: "Thredbo IV" },
  { image: i5, alt: "Thredbo IV" },
  { image: i6, alt: "Thredbo IV" },
  { image: i7, alt: "Thredbo IV" },
  { image: i8, alt: "Thredbo IV" },
];

export default thredbo4Col;
