import React from "react";
import { Link } from "react-router-dom";

function renderLink(link) {
  if (link) {
    if (link.theLink && link.linkText) {
      return (
        <Link to={link.theLink} className='exLink'>
          {link.linkText}
        </Link>
      );
    }
  }
}
function renderLinkOut(linkOut) {
  if (linkOut) {
    if (linkOut.theLink && linkOut.linkText) {
      return (
        <a
          className='exLink'
          target='_blank'
          rel='noreferrer'
          href={linkOut.theLink}
        >
          {linkOut.linkText}
        </a>
      );
    }
  }
}

function renderYear(year) {
  if (year) {
    return <h2>{year}</h2>;
  }
}

function Exhibition({ exhibition, location, year, date, link, linkOut }) {
  return (
    <div className='exhibitionBox'>
      {renderYear(year)}
      <div>{exhibition}</div>
      <div>{location}</div>
      <div>{date}</div>
      {renderLinkOut(linkOut)}
      {renderLink(link)}
    </div>
  );
}

export default Exhibition;
