import React from "react";
import Square from "../../../components/Square";
import SquareSpacer from "../../../components/SquareSpacer";
import { Helmet } from "react-helmet-async";
import HeaderSpacer from "../../../components/HeaderSpacer";

import SlideShow from "../../../components/SlideShow";
import theOptimist2Collection from "./../../../data/shop/theOptimist2_Data";
import BackButton from "./../../../components/BackButton";

function TheOptimist2() {
  return (
    <div>
      <Helmet>
        <title>TL | The Optimist II</title>
        <meta property='og.title' content='The Optimist II - 2023' />
        <meta property='og.image' content={theOptimist2Collection[0].image} />
      </Helmet>
      <HeaderSpacer />
      <p>
        {" "}
        <strong>The Optimist II - 2023.</strong>
      </p>

      <p>
        <strong>Limited Edition of 25 prints +1 A.P.</strong>
      </p>

      <p>
        Giclee archival pigment print on 310 gsm 100% cotton rag fine art
        archival paper.
      </p>

      <p>Print Size 83cm x 69cm / 32.67in x 27.16in.</p>

      <p>Paper Size 91.5cm x 77.5cm / 36.02in x 30.51in.</p>

      <p>
        Signed and Numbered on the front bottom right of paper, and on the
        reverse side of paper.
      </p>

      <p>
        <strong>$295 AUD each </strong>- Unframed.
      </p>

      <p>Includes FREE postage within Australia only.</p>

      <p>$20 shipping to NZ & $30 anywhere else in the world.</p>

      <p>Item is shipped rolled up in a tube via Australia Post.</p>

      <p>Allow 4-7 working days before shipment is dispatched.</p>

      <SlideShow
        slides={theOptimist2Collection}
        slideShowName='theOptimist2'
        startingIndex={0}
        slideHeight='slideShort'
        scrollOffset={-70}
        shopSlideShow={true}
      />
      <Square
        thePrice='$295.00 - $325.00'
        theTitle='The Optimist II- 2023'
        theLink='https://square.link/u/c8NKOJO1?src=embed'
      />
      <SquareSpacer />
      <BackButton />
    </div>
  );
}

export default TheOptimist2;
