import "./App.css";
import React from "react";
import TheRoutes from "./components/TheRoutes";

import { BrowserRouter } from "react-router-dom";
import NavBar from "./components/NavBar";
import ScrollToTop from "./utils/ScrollToTop";

import { HelmetProvider } from "react-helmet-async";

function App() {
  return (
    <HelmetProvider>
      <div className='App'>
        <BrowserRouter>
          <ScrollToTop />
          <NavBar />
          <TheRoutes />
        </BrowserRouter>
      </div>
    </HelmetProvider>
  );
}

export default App;
