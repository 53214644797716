import React from "react";
// import { Link } from "react-router-dom";

function NavBar() {
  return (
    <div className='navBarContainer'>
      <nav className='navBar'>
        <a className='navBarLink' href='/'>
          Home
        </a>
        <a className='navBarLink' href='/paintings'>
          Paintings
        </a>
        <a className='navBarLink' href='/biography'>
          About
        </a>
        <a className='navBarLink' href='/shop'>
          Shop
        </a>
        {/* <Link className='navBarLink' to='/'>
          Home
        </Link>
        <Link className='navBarLink' to='/paintings'>
          Paintings
        </Link>
        <Link className='navBarLink' to='/bio'>
          About
        </Link>
        <Link className='navBarLink' to='/shop'>
          Shop
        </Link> */}
      </nav>
    </div>
  );
}

export default NavBar;
