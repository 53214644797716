import React from "react";
import { Link } from "react-router-dom";

const UpButton = ({ linkTo }) => {
  return (
    <div className='backButtonBox'>
      <Link className='UpButton' to={linkTo}>
        Go Back
      </Link>
    </div>
  );
};

export default UpButton;
