import React from "react";
import BackButton from "../../components/BackButton";
import NavSpacer from "../../components/NavSpacer";
import SlideShow from "../../components/SlideShow";
import cityscapesCollection from "../../data/Paintings/cityscapes_Data";
import { Helmet } from "react-helmet-async";

function Cityscapes() {
  return (
    <div>
      <Helmet>
        <title>Tyrone Layne | Cityscapes</title>
        <meta property='og.image' content={cityscapesCollection[0].image} />
        <link rel='canonical' href='http://tyronelayne.com/cityscapes' />
      </Helmet>
      <NavSpacer />
      <SlideShow
        slides={cityscapesCollection}
        slideShowName='cityscapes'
        startingIndex={0}
        scrollOffset={-40}
      />
      <BackButton />
    </div>
  );
}

export default Cityscapes;
