import React from "react";
import { Link } from "react-router-dom";
import renderSoldLabel from "./SoldText";

const ImageLink = ({
  source,
  description,
  label,
  sold,
  cssClass,
  linkTo,
  labelLine2,
}) => {
  const renderLabel = (label, sold, labelLine2) => {
    if (label && labelLine2) {
      return (
        <Link className='imgLinkLabelBox imageSpace' to={linkTo}>
          <div className='imageLabel'>
            {label} {renderSoldLabel(sold)}
          </div>
          <div className='imageLabel goGreyNow'>{labelLine2}</div>
        </Link>
      );
    } else if (label) {
      return (
        <Link className='imageLabel imageSpace' to={linkTo}>
          {label}
          {renderSoldLabel(sold)}
        </Link>
      );
    }
  };
  return (
    <React.Fragment>
      <div className='normalImageContainer'>
        <Link className='normalImageBox' to={linkTo}>
          <img
            src={source}
            alt={description}
            id='normalImage'
            className={cssClass}
          />
        </Link>

        {renderLabel(label, sold, labelLine2)}
      </div>
    </React.Fragment>
  );
};

export default ImageLink;
