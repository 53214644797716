import dinnerParty from "../../images/Paintings/Interiors/40_dinnerParty.jpg";
import the35thFloor from "../../images/Paintings/Interiors/1_35thFloor.JPG";
import mercerSt from "../../images/Paintings/Interiors/2_mercerSt.jpg";
import mercerStDetail from "../../images/Paintings/Interiors/3_mercerStDetail.jpg";
import parisApartment from "../../images/Paintings/Interiors/4_parisApartment.jpg";
import woosterSt from "../../images/Paintings/Interiors/5_woosterSt.jpg";
import blackSun from "../../images/Paintings/Interiors/6_blackSun.jpg";
import zoomMeeting from "../../images/Paintings/Interiors/7_zoomMeeting.jpg";
import familyDinner from "../../images/Paintings/Interiors/37_familyDinner.jpg";
import feedingTime from "../../images/Paintings/Interiors/8_feedingTime.jpg";
import freshCoatofPaint from "../../images/Paintings/Interiors/9_freshCoatofPaint.jpg";
import champagneOnBalcony from "../../images/Paintings/Interiors/10_champagneOnBalcony.jpg";
import theDiamondHeist from "../../images/Paintings/Interiors/11_theDiamondHeist.jpg";
import bobs65th from "../../images/Paintings/Interiors/12_bobs65th.jpg";
import bobs65thDetail from "../../images/Paintings/Interiors/13_bobs65thDetail.jpg";
import uberEats from "../../images/Paintings/Interiors/14_uberEats.jpg";
import christmasMorning from "../../images/Paintings/Interiors/15_christmasMorning.jpg";
import postWorkoutDinningRoom from "../../images/Paintings/Interiors/38_postWorkoutDiningRoom.jpg";
import postWorkoutBedroom from "../../images/Paintings/Interiors/39_postWorkoutBedroom.jpg";
import sohoLoft2 from "../../images/Paintings/Interiors/16_sohoLoft2.jpg";
import masterbedroom from "../../images/Paintings/Interiors/17_masterbedroom.jpg";
import masterBedroom2 from "../../images/Paintings/Interiors/18_masterBedroom2.jpg";
import pinkAtNight from "../../images/Paintings/Interiors/19_pinkAtNight.jpg";
import snowyTribeca from "../../images/Paintings/Interiors/20_snowyTribeca.jpg";
import earlyColony from "../../images/Paintings/Interiors/21_earlyColony.jpg";
import earlyColony2 from "../../images/Paintings/Interiors/22_earlyColony2.jpg";
import metaverseLoftParty from "../../images/Paintings/Interiors/23_metaverseLoftParty.jpg";
import breakfastTribeca from "../../images/Paintings/Interiors/24_breakfastTribeca.jpg";
import cryingChild from "../../images/Paintings/Interiors/25_cryingChild.jpg";
import sickRainyMorning from "../../images/Paintings/Interiors/26_sickRainyMorning.jpg";
import isoInParis from "../../images/Paintings/Interiors/27_isoInParis.jpg";
import isoInSpain from "../../images/Paintings/Interiors/28_isoInSpain.jpg";
import isoInNewYork from "../../images/Paintings/Interiors/29_isoInNewYork.jpg";
import isoInItaly from "../../images/Paintings/Interiors/30_isoInItaly.jpg";
import cheeseAndTomatoToast from "../../images/Paintings/Interiors/31_cheeseAndTomatoToast.jpg";
import nohoApartment from "../../images/Paintings/Interiors/32_nohoApartment.jpg";
import homeOffice from "../../images/Paintings/Interiors/33_homeOffice.jpg";
import newYorkFashionWeek from "../../images/Paintings/Interiors/34_newYorkFashionWeek.jpg";
import hollywoodHills from "../../images/Paintings/Interiors/35_hollywoodHills.jpg";
import the35thFloorHalf from "../../images/Paintings/Interiors/36_35thFloorHalf.jpg";


const interiorsCollection = [
  {
    image: dinnerParty,
    title: "The Dinner Party",
    description: "Oil on linen. 45.5cm X 45.5cm. 2022-2023",
    alt: "The Dinner Party. Oil on linen. 45.5cm X 45.5cm. 2022-2023",
  },
  {
    image: the35thFloor,
    title: "35th Floor",
    description: "Oil on canvas. 198cm x 304cm(Diptych). 2020",
    alt: "35th Floor. Oil on canvas. 198cm x 304cm(Diptych). 2020",
  },
  {
    image: mercerSt,
    title: "Snowy Night on Mercer St",
    description: "Oil on linen. 84cm X 84cm. 2020",
    alt: "Snowy Night on Mercer St. Oil on linen. 84cm X 84cm. 2020",
  },
  {
    image: mercerStDetail,
    title: "Snowy Night on Mercer St(Detail)",
    description: "Oil on linen. 84cm X 84cm. 2020",
    alt: "Snowy Night on Mercer St. Oil on linen. 84cm X 84cm. 2020",
  },
  {
    image: parisApartment,
    title: "Paris Apartment",
    description: "Oil on linen. 35cm X 40cm. 2019",
    alt: "Paris Apartment. Oil on linen. 35cm X 40cm. 2019",
  },
  {
    image: woosterSt,
    title: "Wooster Street Loft",
    description: "Oil on linen. 36cm x 41cm. 2022",
    alt: "Wooster Street Loft. Oil on linen. 36cm x 41cm. 2022",
  },
  {
    image: blackSun,
    title: "Black Sun",
    description: "Oil on linen. 22.5cm X 20cm. 2020",
    alt: "Black Sun. Oil on linen. 22.5cm X 20cm. 2020",
  },
  {
    image: zoomMeeting,
    title: "Zoom Meeting",
    description: "Oil on linen. 22.5cm X 20cm. 2020",
    alt: "Zoom Meeting. Oil on linen. 22.5cm X 20cm. 2020",
  },
  {
    image: familyDinner,
    title: "The Family Dinner",
    description: "Oil on linen. 76cm X 76cm. 2023",
    alt: "The Family Dinner. Oil on linen. 76cm X 76cm. 2023",
  },
  {
    image: feedingTime,
    title: "(Parisian Mini Series) Feeding Time",
    description: "Oil on linen. 22.5cm X 20cm. 2021",
    alt: "(Parisian Mini Series) Feeding Time. Oil on linen. 22.5cm X 20cm. 2021",
  },
  {
    image: freshCoatofPaint,
    title: "(Parisian Mini Series) Fresh Coat of Paint",
    description: "Oil on linen. 22.5cm X 20cm. 2021",
    alt: "(Parisian Mini Series) Fresh Coat of Paint. Oil on linen. 22.5cm X 20cm. 2021",
  },
  {
    image: champagneOnBalcony,
    title: "(Parisian Mini Series) Champagne on the Balcony",
    description: "Oil on linen. 22.5cm X 20cm. 2021",
    alt: "(Parisian Mini Series) Champagne on the Balcony. Oil on linen. 22.5cm X 20cm. 2021",
  },
  {
    image: theDiamondHeist,
    title: "(Parisian Mini Series) The Diamond Heist",
    description: "Oil on linen. 22.5cm X 20cm. 2021",
    alt: "(Parisian Mini Series) The Diamond Heist. Oil on linen. 22.5cm X 20cm. 2021",
  },
  {
    image: bobs65th,
    title: "Bob's 65th Birthday",
    description: "Oil & Acrylic on linen. 84cm X 84cm. 2019",
    alt: "Bob's 65th Birthday. Oil & Acrylic on linen. 84cm X 84cm. 2019",
  },
  {
    image: bobs65thDetail,
    title: "Bob's 65th Birthday(detail)",
    description: "Oil & Acrylic on linen. 84cm X 84cm. 2019",
    alt: "Bob's 65th Birthday(detail). Oil & Acrylic on linen. 84cm X 84cm. 2019",
  },
  {
    image: uberEats,
    title: "Uber Eats - White Cat - and a Gucci Sweater",
    description: "Oil on linen. 22.5cm X 20cm. 2021",
    alt: "Uber Eats - White Cat - and a Gucci Sweater. Oil on linen. 22.5cm X 20cm. 2021",
  },
  {
    image: christmasMorning,
    title: "Christmas Morning",
    description: "Oil on linen. 22.5cm X 20cm. 2020",
    alt: "Christmas Morning. Oil on linen. 22.5cm X 20cm. 2020",
  },
  {
    image: postWorkoutDinningRoom,
    title: "Post-Workout (Dinning Room)",
    description: "Oil on linen. 22.5cm X 20cm. 2023",
    alt: "Post-Workout (Dinning Room). Oil on linen. 22.5cm X 20cm. 2023",
  },
  {
    image: postWorkoutBedroom,
    title: "Post-Workout (Bedroom)",
    description: "Oil on linen. 22.5cm X 20cm. 2023",
    alt: "Post-Workout (Bedroom). Oil on linen. 22.5cm X 20cm. 2023",
  },
  {
    image: sohoLoft2,
    title: "Soho Loft II",
    description: "Ballpoint pen on paper. 21cm X 26cm. 2018",
    alt: "Soho Loft II. Ballpoint pen on paper. 21cm X 26cm. 2018",
  },
  {
    image: masterbedroom,
    title: "Master Bedroom",
    description: "Ballpoint pen on paper. 21cm X 26cm. 2018",
    alt: "Master Bedroom. Ballpoint pen on paper. 21cm X 26cm. 2018",
  },
  {
    image: masterBedroom2,
    title: "Master Bedroom II",
    description: "Oil on linen. 22.5cm X 20cm. 2021",
    alt: "Master Bedroom II. Oil on linen. 22.5cm X 20cm. 2021",
  },
  {
    image: pinkAtNight,
    title: "Pink at Night Shepherds Delight",
    description: "Oil on linen. 22.5cm X 20cm. 2020",
    alt: "Pink at Night Shepherds Delight. Oil on linen. 22.5cm X 20cm. 2020",
  },
  {
    image: snowyTribeca,
    title: "Snowy Day in Tribeca",
    description: "Oil on linen. 41cm X 36cm. 2020",
    alt: "Snowy Day in Tribeca. Oil on linen. 41cm X 36cm. 2020",
  },
  {
    image: earlyColony,
    title: "Early Colony",
    description: "Oil on board. 38cm X 30.5cm. 2021",
    alt: "Early Colony. Oil on board. 38cm X 30.5cm. 2021",
  },
  {
    image: earlyColony2,
    title: "Early Colony II",
    description: "Oil on board. 50cm X 50cm. 2021-2022",
    alt: "Early Colony II. Oil on board. 50cm X 50cm. 2021-2022",
  },
  {
    image: metaverseLoftParty,
    title: "Loft Party in the Metaverse",
    description: "Oil on linen. 36cm x 41cm. 2021. (released as 1/1 NFT)",
    alt: "Loft Party in the Metaverse. Oil on linen. 36cm x 41cm. 2021. (released as 1/1 NFT)",
  },
  {
    image: breakfastTribeca,
    title: "(TriBeCa family mini series) Breakfast in Tribeca",
    description: "Oil on linen. 22.5cm x 20cm. 2021",
    alt: "(TriBeCa family mini series) Breakfast in Tribeca. Oil on linen. 22.5cm x 20cm. 2021",
  },
  {
    image: cryingChild,
    title: "(TriBeCa family mini series) Crying Child",
    description: "Oil on linen. 22.5cm x 20cm. 2021",
    alt: "(TriBeCa family mini series) Crying Child. Oil on linen. 22.5cm x 20cm. 2021",
  },
  {
    image: sickRainyMorning,
    title: "(TriBeCa family mini series) Sicky on a rainy Tribeca Morning",
    description: "Oil on linen. 22.5cm x 20cm. 2021",
    alt: "(TriBeCa family mini series) Sicky on a rainy Tribeca Morning. Oil on linen. 22.5cm x 20cm. 2021",
  },
  {
    image: isoInParis,
    title: "Iso in Paris",
    description: "Oil on linen. 22.5cm x 20cm. 2020",
    alt: "Iso in Paris. Oil on linen. 22.5cm x 20cm. 2020",
  },
  {
    image: isoInSpain,
    title: "Iso in Spain",
    description: "Oil on linen. 22.5cm x 20cm. 2020",
    alt: "Iso in Spain. Oil on linen. 22.5cm x 20cm. 2020",
  },
  {
    image: isoInNewYork,
    title: "Iso in New York",
    description: "Oil on linen. 22.5cm x 20cm. 2020",
    alt: "Iso in New York. Oil on linen. 22.5cm x 20cm. 2020",
  },
  {
    image: isoInItaly,
    title: "Iso in Italy",
    description: "Oil on linen. 22.5cm x 20cm. 2020",
    alt: "Iso in Italy. Oil on linen. 22.5cm x 20cm. 2020",
  },
  {
    image: cheeseAndTomatoToast,
    title: "Cheese & Tomato on Toast",
    description: "Oil on linen. 22.5cm x 20cm. 2020",
    alt: "Cheese & Tomato on Toast. Oil on linen. 22.5cm x 20cm. 2020",
  },
  {
    image: nohoApartment,
    title: "NoHo Apartment",
    description: "Ballpoint pen on paper. 21cm X 26cm. 2017",
    alt: "NoHo Apartment. Ballpoint pen on paper. 21cm X 26cm. 2017",
  },
  {
    image: homeOffice,
    title: "Home Office",
    description: "Oil on linen. 22.5cm x 20cm. 2020",
    alt: "Home Office. Oil on linen. 22.5cm x 20cm. 2020",
  },
  {
    image: newYorkFashionWeek,
    title: "New York Fashion Week",
    description: "Oil on linen. 22.5cm x 20cm. 2019/2020",
    alt: "New York Fashion Week. Oil on linen. 22.5cm x 20cm. 2019/2020",
  },
  {
    image: hollywoodHills,
    title: "Parenting in the Hollywood Hills",
    description: "Oil on linen. 41cm x 36cm. 2020",
    alt: "Parenting in the Hollywood Hills. Oil on linen. 41cm x 36cm. 2020",
  },
  {
    image: the35thFloorHalf,
    title: "35th Floor",
    description: "Oil on canvas. 198cm x 304cm(Half). 2020",
    alt: "35th Floor. Oil on canvas. 198cm x 304cm(Half). 2020",
  },
];
export default interiorsCollection;
