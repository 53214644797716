import React from "react";
import ImageLink from "../components/ImageLink";
import Title from "../components/Title";
// import imageScroller from "../utils/imageScroller";
import abstracts from "../images/Paintings/Abstracts/0_previewAbstracts.jpg";
import HeaderSpacer from "../components/HeaderSpacer";
import cityscapes from "../images/Paintings/Cityscapes/0_previewCityscapes.JPG";
import interiors from "../images/Paintings/Interiors/0_previewImageInteriors.jpg";
import misc from "../images/Paintings/misc/0_previewImageMisc.jpg";
import peoplescapesNew from "../images/Paintings/Peoplescapes_2018-now/0_previewImageP2018.JPG";
import peoplescapesOld from "../images/Paintings/Peoplescapes_2007-2017/0_previewImageP2007.jpg";
import FloatingSubtitle from "../components/FloatingSubtitle";
import UpButton from "../components/UpButton";
import { Helmet } from "react-helmet-async";
import returnYear from "../utils/GetYear";

function Paintings() {
  return (
    <div>
      <Helmet>
        <title>Tyrone Layne | Paintings</title>
        <meta property='og.image' content={peoplescapesNew} />
        <link rel='canonical' href='http://tyronelayne.com/paintings' />
      </Helmet>
      <Title subtitle='Paintings' />
      <FloatingSubtitle text='paintings' extraCSS='paintingTitle' />
      <HeaderSpacer />
      <ImageLink
        source={peoplescapesNew}
        description={`Click to view Peoplescapes 2018 - ${returnYear()} gallery.`}
        cssClass='peepNewPreview'
        linkTo='/paintings/peoplescapes-new'
        label={`Peoplescapes 2018 - ${returnYear()}`}
      />
      <ImageLink
        source={peoplescapesOld}
        description='Click to view Peoplescapes 2007 - 2017 gallery.'
        cssClass='peepOldPreview'
        linkTo='/paintings/peoplescapes-old'
        label='Peoplescapes 2007 - 2017'
      />
      <ImageLink
        source={abstracts}
        description='Click to view Abstracts gallery.'
        cssClass='abstractPreview'
        linkTo='/paintings/abstracts'
        label='Abstracts'
      />
      <ImageLink
        source={cityscapes}
        description='Click to view Cityscapes gallery.'
        cssClass='cityscapesPreview'
        linkTo='/paintings/cityscapes'
        label='Cityscapes'
      />
      <ImageLink
        source={interiors}
        description='Click to view Interiors gallery.'
        cssClass='interiorsPreview'
        linkTo='/paintings/interiors'
        label='Interiors'
      />
      <ImageLink
        source={misc}
        description='Click to view Miscellaneous gallery.'
        cssClass='miscPreview'
        linkTo='/paintings/misc'
        label='Miscellaneous'
      />
      <UpButton linkTo='/' />
    </div>
  );
}

export default Paintings;
