import React from "react";

const renderSold = sold => {
  if (sold) {
    return <span className='sold'> -SOLD</span>;
  }
};

const renderPrice = price => {
  if (price) {
    return <span className='price'> ${price}</span>;
  }
};

const Slide = ({
  image,
  title,
  description,
  alt,
  sold,
  price,
  slideHeight,
  slideShowName,
  shopSlide,
}) => {
  // const isItShort = slideHeight => {
  //   if (slideHeight) {
  //     document.querySelector(".slide").style.height = "65vh";
  //   }
  // };

  // useEffect(() => {
  //   isItShort(slideHeight);
  // });

  const isItAShopSlide = () => {
    if (shopSlide) {
      return "shopSlide";
    } else {
      return "";
    }
  };

  return (
    <div className={`slide ${slideHeight}`} id={isItAShopSlide()}>
      <div className={`slideBox `}>
        <img className={`slideImage `} src={image} alt={alt} />
        <div className='slideLabel'>
          <div className='labelText'>
            {title}
            {renderSold(sold)}
          </div>
          <div className='labelText secondLine'>
            {description}
            {renderPrice(price)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slide;
