import React from "react";
import HeaderSpacer from "../../../components/HeaderSpacer";
import Square from "../../../components/Square";
import thredbo4Col from "../../../data/shop/20thPrintThredbo4_Data";
import BackButton from "../../../components/BackButton";
import SquareSpacer from "../../../components/SquareSpacer";
import SlideShow from "../../../components/SlideShow";
import { Helmet } from "react-helmet-async";

function Thredbo4() {
  return (
    <div>
      <Helmet>
        <title>TL | Thredbo IV</title>
        <meta property='og.title' content='Thredbo IV - 2023' />
        <meta property='og.image' content={thredbo4Col[0].image} />
      </Helmet>
      <HeaderSpacer />

      <p>
        <strong>Thredbo IV - 2023.</strong>
      </p>
      <p>
        <strong>Limited Edition of 35 prints +2 A.P's.</strong>
      </p>
      <p>
        Giclee archival pigment print on 310 gsm 100% cotton rag fine art
        archival paper.
      </p>
      <p>Print Size 74cm x 82cm / 29.13in x 32.28in.</p>
      <p>Paper Size 82cm x 90cm / 32.28in x 35.43in.</p>
      <p>
        Signed and Numbered on the front bottom right of paper, and on the
        reverse side of paper.
      </p>
      <p>
        <strong>$290 AUD each</strong> - Unframed.
      </p>
      <p>Includes FREE postage within Australia only.</p>
      <p>$20 shipping to NZ & $30 anywhere else in the world.</p>
      <p>Item is shipped rolled up in a tube via Australia Post.</p>
      <p>Allow 4-7 working days before shipment is dispatched.</p>
      <p>
      </p>
      <SlideShow
        slides={thredbo4Col}
        slideShowName='Thredbo4Slideshow'
        startingIndex={0}
        scrollOffset={-45}
        shopSlideShow={true}
      />
      <Square
        thePrice='$290.00 - $320.00'
        theTitle='Thredbo IV'
        theLink='https://square.link/u/bLoMJV2w?src=embed'
      />
      <SquareSpacer />
      <BackButton />
    </div>
  );
}

export default Thredbo4;
