import React from "react";
import SlideShow from "../components/SlideShow";
import imageCollection from "../data/homeSlideShow_Data";
import Exhibitions from "../components/Exhibitions";
import upcomingExhibitions from "../data/upcomingExhibitions_Data";
import Title from "../components/Title";
import { Helmet } from "react-helmet-async";
import FloatingSubtitle from "../components/FloatingSubtitle";

function Home() {
  return (
    <div>
      <Helmet>
        <title>Tyrone Layne, Painter</title>
        <meta property='og:title' content='Tyrone Layne, Painter' />
        <meta
          property='og:description'
          content='New Zealand Contemporary Artist Tyrone Layne based in Sydney, Australia. Painter of people in their cities.'
        />
        <meta property='og:image' content={imageCollection[0].image} />
        <meta property='og:type' content='website' />
        <link rel='canonical' href='http://tyronelayne.com' />
      </Helmet>
      <Title subtitle='painter' />
      <FloatingSubtitle text='painter' extraCSS='homeTitle' disappear={true} />

      <SlideShow
        slides={imageCollection}
        slideShowName='recentWorks'
        startingIndex={0}
        scrollOffset={-40}
      />
      <br />
      <br />
      <Exhibitions exhibitions={upcomingExhibitions} />
      <br />
      <br />
    </div>
  );
}

export default Home;
