import thirtyOneDegrees from "../../images/Paintings/Peoplescapes_2007-2017/1_thirtyOneDegrees.jpg";

import sohoNyc from "../../images/Paintings/Peoplescapes_2007-2017/2_sohoNyc.jpg";
import londoners from "../../images/Paintings/Peoplescapes_2007-2017/3_londoners.jpg";
import vivianGallery from "../../images/Paintings/Peoplescapes_2007-2017/4_vivianGallery.jpg";
import slowDownKidsAbout from "../../images/Paintings/Peoplescapes_2007-2017/5_slowDownKidsAbout.jpg";
import pegasusAndHulk from "../../images/Paintings/Peoplescapes_2007-2017/6_pegasusAndHulk.jpg";
import stKilda1 from "../../images/Paintings/Peoplescapes_2007-2017/7_stKilda1.jpg";
import stKilda2 from "../../images/Paintings/Peoplescapes_2007-2017/8_stKilda2.jpg";
import paddington from "../../images/Paintings/Peoplescapes_2007-2017/9_paddington.jpg";
import maroubraBeach from "../../images/Paintings/Peoplescapes_2007-2017/10_maroubraBeach.jpg";
import manly from "../../images/Paintings/Peoplescapes_2007-2017/11_manly.jpg";
import classTripToTheCity from "../../images/Paintings/Peoplescapes_2007-2017/12_classTripToTheCity.jpg";
import bikiniWars2 from "../../images/Paintings/Peoplescapes_2007-2017/13_bikiniWars2.jpg";
import newYorkNewYork from "../../images/Paintings/Peoplescapes_2007-2017/14_newYorkNewYork.jpg";
import northBondi from "../../images/Paintings/Peoplescapes_2007-2017/15_northBondi.jpg";
import waterlooToSurryHills from "../../images/Paintings/Peoplescapes_2007-2017/16_waterlooToSurryHills.jpg";
import kuttiBeach from "../../images/Paintings/Peoplescapes_2007-2017/17_kuttiBeach.jpg";
import campCove from "../../images/Paintings/Peoplescapes_2007-2017/18_campCove.jpg";
import jazzInThePark from "../../images/Paintings/Peoplescapes_2007-2017/19_jazzInThePark.jpg";
import fiveWays from "../../images/Paintings/Peoplescapes_2007-2017/20_fiveWays.jpg";
import angelWings from "../../images/Paintings/Peoplescapes_2007-2017/21_angelWings.jpg";
import summerHolidays from "../../images/Paintings/Peoplescapes_2007-2017/22_summerHolidays.jpg";
import bronteBeach from "../../images/Paintings/Peoplescapes_2007-2017/23_bronteBeach.jpg";
import bondiSummers3 from "../../images/Paintings/Peoplescapes_2007-2017/24_bondiSummers3.jpg";
import bondiSummers4 from "../../images/Paintings/Peoplescapes_2007-2017/25_bondiSummers4.jpg";
import bondiSummers5 from "../../images/Paintings/Peoplescapes_2007-2017/26_bondiSummers5.jpg";
import bondiSummers6 from "../../images/Paintings/Peoplescapes_2007-2017/27_bondiSummers6.jpg";
import paddington3 from "../../images/Paintings/Peoplescapes_2007-2017/28_paddington3.jpg";
import pinkAfternoon2 from "../../images/Paintings/Peoplescapes_2007-2017/29_pinkAfternoon2.jpg";
import campCove2 from "../../images/Paintings/Peoplescapes_2007-2017/30_campCove2.jpg";
import centennialPark from "../../images/Paintings/Peoplescapes_2007-2017/31_centennialPark.jpg";
import theVolunteers from "../../images/Paintings/Peoplescapes_2007-2017/32_theVolunteers.jpg";
import whatHappensOnEarth from "../../images/Paintings/Peoplescapes_2007-2017/33_whatHappensOnEarth.jpg";
import familyVacation from "../../images/Paintings/Peoplescapes_2007-2017/34_familyVacation.jpg";
import inflatablePineapple from "../../images/Paintings/Peoplescapes_2007-2017/35_inflatablePineapple.jpg";
import theInterview from "../../images/Paintings/Peoplescapes_2007-2017/36_theInterview.jpg";
import theInterview2 from "../../images/Paintings/Peoplescapes_2007-2017/37_theInterview2.jpg";
import theInterview3 from "../../images/Paintings/Peoplescapes_2007-2017/38_theInterview3.jpg";
import e1London from "../../images/Paintings/Peoplescapes_2007-2017/39_e1London.jpg";
import hydeParkLondon from "../../images/Paintings/Peoplescapes_2007-2017/40_hydeParkLondon.jpg";
import soloshow2017 from "../../images/Paintings/Peoplescapes_2007-2017/41-soloshow2017.jpg";
import avocaBeach from "../../images/Paintings/Peoplescapes_2007-2017/42_avocaBeach.jpg";
import wandaBeach from "../../images/Paintings/Peoplescapes_2007-2017/43_wandaBeach.jpg";
import paddington2 from "../../images/Paintings/Peoplescapes_2007-2017/44_paddington2.jpg";
import familyVacation2 from "../../images/Paintings/Peoplescapes_2007-2017/45_familyVacation2.jpg";
import twinsAtBronte from "../../images/Paintings/Peoplescapes_2007-2017/46_twinsAtBronte.jpg";
import theRehearsal2 from "../../images/Paintings/Peoplescapes_2007-2017/47_theRehearsal2.jpg";
import surfersParadise from "../../images/Paintings/Peoplescapes_2007-2017/48_surfersParadise.jpg";
import twentyNineDegrees from "../../images/Paintings/Peoplescapes_2007-2017/49_twentyNineDegrees.jpg";
import weirdTogether from "../../images/Paintings/Peoplescapes_2007-2017/50_weirdTogether.jpg";
import theRehearsal from "../../images/Paintings/Peoplescapes_2007-2017/51_theRehearsal.jpg";
import kidsAtClovelly from "../../images/Paintings/Peoplescapes_2007-2017/52_kidsAtClovelly.jpg";
import aDayInPaddo from "../../images/Paintings/Peoplescapes_2007-2017/53_aDayInPaddo.jpg";
import businessTripToHK from "../../images/Paintings/Peoplescapes_2007-2017/54_businessTripToHK.jpg";
import bondiSummers from "../../images/Paintings/Peoplescapes_2007-2017/55_bondiSummers.jpg";
import sydney from "../../images/Paintings/Peoplescapes_2007-2017/56_sydney.jpg";
import bondiSummers2 from "../../images/Paintings/Peoplescapes_2007-2017/57_bondiSummers2.jpg";
import sydney2 from "../../images/Paintings/Peoplescapes_2007-2017/58_sydney2.jpg";
import theLongWeekend from "../../images/Paintings/Peoplescapes_2007-2017/59_theLongWeekend.jpg";
import saltInTheirEyes from "../../images/Paintings/Peoplescapes_2007-2017/60_saltInTheirEyes.jpg";
import canaryWharf from "../../images/Paintings/Peoplescapes_2007-2017/61_canaryWharf.jpg";
import takapunaBeach from "../../images/Paintings/Peoplescapes_2007-2017/62_takapunaBeach.jpg";
import coffeeBreak from "../../images/Paintings/Peoplescapes_2007-2017/63_coffeeBreak.jpg";
import frankiesBikinis from "../../images/Paintings/Peoplescapes_2007-2017/64_frankiesBikinis.jpg";
import theOpeningReception from "../../images/Paintings/Peoplescapes_2007-2017/65_theOpeningReception.jpg";
import touchRugbyTamarama from "../../images/Paintings/Peoplescapes_2007-2017/66_touchRugbyTamarama.jpg";
import saturdayAfternoon from "../../images/Paintings/Peoplescapes_2007-2017/67_saturdayAfternoon.jpg";
import paddingtonStudio from "../../images/Paintings/Peoplescapes_2007-2017/68_paddingtonStudio.jpg";
import saintCloche from "../../images/Paintings/Peoplescapes_2007-2017/69_saintCloche.jpg";
import pinkAfternoon from "../../images/Paintings/Peoplescapes_2007-2017/70_pinkAfternoon.jpg";
import unicornsAtBondi from "../../images/Paintings/Peoplescapes_2007-2017/71_unicornsAtBondi.jpg";
import boxingDayTestMatch from "../../images/Paintings/Peoplescapes_2007-2017/72_boxingDayTestMatch.jpg";
import bondiNippers from "../../images/Paintings/Peoplescapes_2007-2017/73_bondiNippers.jpg";
import fedSquare from "../../images/Paintings/Peoplescapes_2007-2017/74_fedSquare.jpg";
import northBondi2 from "../../images/Paintings/Peoplescapes_2007-2017/75_northBondi2.jpg";
import milkBeach2 from "../../images/Paintings/Peoplescapes_2007-2017/76_milkBeach2.jpg";
import chinamansBeach from "../../images/Paintings/Peoplescapes_2007-2017/77_chinamansBeach.jpg";
import northBondi3 from "../../images/Paintings/Peoplescapes_2007-2017/78_northBondi3.jpg";
import pinkAfernoon3 from "../../images/Paintings/Peoplescapes_2007-2017/79_pinkAfernoon3.jpg";
import twoDegrees from "../../images/Paintings/Peoplescapes_2007-2017/80_twoDegrees.jpg";
import longBlack from "../../images/Paintings/Peoplescapes_2007-2017/81_longBlack.jpg";
import people108 from "../../images/Paintings/Peoplescapes_2007-2017/82_people108.jpg";
import studio14 from "../../images/Paintings/Peoplescapes_2007-2017/83_studio14.JPG";
import officeParty from "../../images/Paintings/Peoplescapes_2007-2017/84_officeParty.jpg";
import mrWhippy from "../../images/Paintings/Peoplescapes_2007-2017/85_mrWhippy.jpg";
import soloShow2016 from "../../images/Paintings/Peoplescapes_2007-2017/86_soloShow2016.jpg";
import southPiha from "../../images/Paintings/Peoplescapes_2007-2017/87_southPiha.jpg";
import timesSquare from "../../images/Paintings/Peoplescapes_2007-2017/88_timesSquare.jpg";
import afternoonYogaClass from "../../images/Paintings/Peoplescapes_2007-2017/89_afternoonYogaClass.jpg";

const peopleScapesOldCollection = [
  {
    image: thirtyOneDegrees,
    title: "Thirty One Degrees",
    description: "Oil & acrylic on canvas. 152cm X 198cm. 2016-2017",
    alt: "Thirty One Degrees. Oil & acrylic on canvas. 152cm X 198cm. 2016-2017",
  },
  {
    image: sohoNyc,
    title: "Soho NYC",
    description: "Oil & Acrylic on linen. 84cm X 91cm. 2017",
    alt: "Soho NYC. Oil & Acrylic on linen. 84cm X 91cm. 2017",
  },
  {
    image: londoners,
    title: "Londoners",
    description: "Oil on board. 100cm x 120cm. 2012",
    alt: "Londoners. Oil on board. 100cm x 120cm. 2012",
  },
  {
    image: vivianGallery,
    title: "Vivian Gallery",
    description: "Group Show 2015 Matakana New Zealand",
    alt: "Vivian Gallery- Group Show 2015 Matakana New Zealand",
  },
  {
    image: slowDownKidsAbout,
    title: "Slow Down Kids About",
    description: "Oil & Acrylic on linen. 61cm X 61cm. 2016",
    alt: "Slow Down Kids About. Oil & Acrylic on linen. 61cm X 61cm. 2016",
  },
  {
    image: pegasusAndHulk,
    title: "Pegasus & Hulk at the Beach",
    description: "Oil & Acrylic on canvas. 140cm X 150cm. 2016",
    alt: "Pegasus & Hulk at the Beach. Oil & Acrylic on canvas. 140cm X 150cm. 2016",
  },
  {
    image: stKilda1,
    title: "St Kilda Summers I",
    description: "Oil & Acrylic on board. 60cm X 60cm. 2017",
    alt: "St Kilda Summers I. Oil & Acrylic on board. 60cm X 60cm. 2017",
  },
  {
    image: stKilda2,
    title: "St Kilda Summers II",
    description: "Oil & Acrylic on board. 60cm X 60cm. 2017",
    alt: "St Kilda Summers II. Oil & Acrylic on board. 60cm X 60cm. 2017",
  },
  {
    image: paddington,
    title: "Paddington",
    description: "Oil & Acrylic on canvas. 140cm X 150cm. 2016",
    alt: "Paddington. Oil & Acrylic on canvas. 140cm X 150cm. 2016",
  },
  {
    image: maroubraBeach,
    title: "Maroubra Beach",
    description: "Oil & Acrylic on board. 60cm X 60cm. 2016",
    alt: "Maroubra Beach. Oil & Acrylic on board. 60cm X 60cm. 2016",
  },
  {
    image: manly,
    title: "Manly",
    description: "Oil & Acrylic on canvas. 60cm X 60cm. 2016",
    alt: "Manly. Oil & Acrylic on canvas. 60cm X 60cm. 2016",
  },
  {
    image: classTripToTheCity,
    title: "Class Trip to the City",
    description: "Oil & Acrylic on board. 60cm X 60cm. 2015-2016",
    alt: "Class Trip to the City. Oil & Acrylic on board. 60cm X 60cm. 2015-2016",
  },
  {
    image: bikiniWars2,
    title: "Bikini Wars II",
    description: "Oil & Acrylic on board. 60cm X 60cm. 2016",
    alt: "Bikini Wars II. Oil & Acrylic on board. 60cm X 60cm. 2016",
  },
  {
    image: newYorkNewYork,
    title: "New York New York",
    description: "Oil & Acrylic on canvas. 127cm X 238cm. 2016",
    alt: "New York New York. Oil & Acrylic on canvas. 127cm X 238cm. 2016",
  },
  {
    image: northBondi,
    title: "North Bondi",
    description: "Oil & Acrylic on board. 60cm X 60cm. 2015-2016",
    alt: "North Bondi. Oil & Acrylic on board. 60cm X 60cm. 2015-2016",
  },
  {
    image: waterlooToSurryHills,
    title: "Commute from Waterloo to Surry Hills",
    description: "Oil & Acrylic on linen. 91cm X 107cm. 2017",
    alt: "Commute from Waterloo to Surry Hills. Oil & Acrylic on linen. 91cm X 107cm. 2017",
  },
  {
    image: kuttiBeach,
    title: "Kutti Beach- Vaucluse",
    description: "Oil & Acrylic on linen. 56cm X 66cm. 2017",
    alt: "Kutti Beach- Vaucluse. Oil & Acrylic on linen. 56cm X 66cm. 2017",
  },
  {
    image: campCove,
    title: "Camp Cove",
    description: "Oil & Acrylic on linen. 56cm X 66cm. 2017",
    alt: "Camp Cove. Oil & Acrylic on linen. 56cm X 66cm. 2017",
  },
  {
    image: jazzInThePark,
    title: "Jazz in the Park",
    description: "Oil & Acrylic on linen. 56cm X 66cm. 2017",
    alt: "Jazz in the Park. Oil & Acrylic on linen. 56cm X 66cm. 2017",
  },
  {
    image: fiveWays,
    title: "Five Ways- Paddington",
    description: "Oil & Acrylic on linen. 56cm X 66cm. 2017",
    alt: "Five Ways- Paddington. Oil & Acrylic on linen. 56cm X 66cm. 2017",
  },
  {
    image: angelWings,
    title: "Angel Wings & Flamingos",
    description: "Oil & Acrylic on linen. 137cm X 167cm. 2017",
    alt: "Angel Wings & Flamingos. Oil & Acrylic on linen. 137cm X 167cm. 2017",
  },
  {
    image: summerHolidays,
    title: "Summer Holidays",
    description: "Oil & Acrylic on linen. 91cm X 91cm. 2017",
    alt: "Summer Holidays. Oil & Acrylic on linen. 91cm X 91cm. 2017",
  },
  {
    image: bronteBeach,
    title: "Bronte Beach",
    description: "Oil & Acrylic on board. 58cm X 58cm. 2015",
    alt: "Bronte Beach. Oil & Acrylic on board. 58cm X 58cm. 2015",
  },
  {
    image: bondiSummers3,
    title: "Bondi Summers III",
    description: "Oil & Acrylic on canvas. 140cm X 150cm. 2016",
    alt: "Bondi Summers III. Oil & Acrylic on canvas. 140cm X 150cm. 2016",
  },
  {
    image: bondiSummers4,
    title: "Bondi Summers IV",
    description: "Oil & Acrylic on canvas. 140cm X 150cm. 2016",
    alt: "Bondi Summers IV. Oil & Acrylic on canvas. 140cm X 150cm. 2016",
  },
  {
    image: bondiSummers5,
    title: "Bondi Summers V",
    description: "Oil & Acrylic on canvas. 140cm X 150cm. 2016",
    alt: "Bondi Summers V. Oil & Acrylic on canvas. 140cm X 150cm. 2016",
  },
  {
    image: bondiSummers6,
    title: "Bondi Summers VI",
    description: "Oil & Acrylic on linen. 122cm X 152cm. 2017",
    alt: "Bondi Summers VI. Oil & Acrylic on linen. 122cm X 152cm. 2017",
  },
  {
    image: paddington3,
    title: "Paddington III",
    description: "Oil & Acrylic on linen. 137cm X 167cm. 2017",
    alt: "Paddington III. Oil & Acrylic on linen. 137cm X 167cm. 2017",
  },
  {
    image: pinkAfternoon2,
    title: "Pink Afternoon II",
    description: "Oil & Acrylic on linen. 137cm X 152cm. 2017",
    alt: "Pink Afternoon II. Oil & Acrylic on linen. 137cm X 152cm. 2017",
  },
  {
    image: campCove2,
    title: "Camp Cove II",
    description: "Oil & Acrylic on linen. 61cm X 61cm. 2017",
    alt: "Camp Cove II. Oil & Acrylic on linen. 61cm X 61cm. 2017",
  },
  {
    image: centennialPark,
    title: "Centennial Park",
    description: "Oil & Acrylic on linen. 61cm X 61cm. 2017",
    alt: "Centennial Park. Oil & Acrylic on linen. 61cm X 61cm. 2017",
  },
  {
    image: theVolunteers,
    title: "The Volunteers",
    description: "Oil & Acrylic on linen. 61cm X 61cm. 2017",
    alt: "The Volunteers. Oil & Acrylic on linen. 61cm X 61cm. 2017",
  },
  {
    image: whatHappensOnEarth,
    title: "What Happens on Earth Stays on Earth",
    description: "Oil & Acrylic on linen. 61cm X 61cm. 2017",
    alt: "What Happens on Earth Stays on Earth. Oil & Acrylic on linen. 61cm X 61cm. 2017",
  },
  {
    image: familyVacation,
    title: "Family Vacation in Hawaii",
    description: "Oil & Acrylic on linen. 71cm X 46cm. 2017",
    alt: "Family Vacation in Hawaii. Oil & Acrylic on linen. 71cm X 46cm. 2017",
  },
  {
    image: inflatablePineapple,
    title: "Inflatable Pineapple",
    description: "Oil & Acrylic on canvas. 150cm X 140cm. 2016",
    alt: "Inflatable Pineapple. Oil & Acrylic on canvas. 150cm X 140cm. 2016",
  },
  {
    image: theInterview,
    title: "The Interview",
    description: "Oil & Acrylic on board. 58cm X 58cm. 2015",
    alt: "The Interview. Oil & Acrylic on board. 58cm X 58cm. 2015",
  },
  {
    image: theInterview2,
    title: "The Interview II",
    description: "Oil & Acrylic on board. 58cm X 58cm. 2015",
    alt: "The Interview II. Oil & Acrylic on board. 58cm X 58cm. 2015",
  },
  {
    image: theInterview3,
    title: "The Interview III",
    description: "Oil & Acrylic on board. 60cm X 60cm. 2016",
    alt: "The Interview III. Oil & Acrylic on board. 60cm X 60cm. 2016",
  },
  {
    image: e1London,
    title: "E1 London",
    description: "Oil & Acrylic on linen. 84cm X 91cm. 2017",
    alt: "E1 London. Oil & Acrylic on linen. 84cm X 91cm. 2017",
  },
  {
    image: hydeParkLondon,
    title: "Hyde Park- London",
    description: "Oil & Acrylic on linen. 84cm X 91cm. 2017",
    alt: "Hyde Park- London. Oil & Acrylic on linen. 84cm X 91cm. 2017",
  },
  {
    image: soloshow2017,
    title: "Eastern Suburbs",
    description: "Solo Show. Saint Cloche. Paddington. Sydney. 2017",
    alt: "Eastern Suburbs. Solo Show. Saint Cloche. Paddington. Sydney. 2017",
  },
  {
    image: avocaBeach,
    title: "Avoca Beach",
    description: "Oil & Acrylic on linen. 122cm X 122cm. 2017",
    alt: "Avoca Beach. Oil & Acrylic on linen. 122cm X 122cm. 2017",
  },
  {
    image: wandaBeach,
    title: "Wanda Beach",
    description: "Oil & Acrylic on linen. 56cm X 66cm. 2017",
    alt: "Wanda Beach. Oil & Acrylic on linen. 56cm X 66cm. 2017",
  },
  {
    image: paddington2,
    title: "Paddington II",
    description: "Oil & Acrylic on canvas. 140cm X 150cm. 2016",
    alt: "Paddington II. Oil & Acrylic on canvas. 140cm X 150cm. 2016",
  },
  {
    image: familyVacation2,
    title: "Family Vacation II",
    description: "Oil & Acrylic on linen. 92cm X 84cm. 2017",
    alt: "Family Vacation II. Oil & Acrylic on linen. 92cm X 84cm. 2017",
  },
  {
    image: twinsAtBronte,
    title: "New born twins at Bronte Beach",
    description: "Oil & Acrylic on board. 60cm X 60cm. 2016",
    alt: "New born twins at Bronte Beach. Oil & Acrylic on board. 60cm X 60cm. 2016",
  },
  {
    image: theRehearsal2,
    title: "The Rehearsal II",
    description: "Oil & Acrylic on board. 60cm X 60cm. 2016",
    alt: "The Rehearsal II. Oil & Acrylic on board. 60cm X 60cm. 2016",
  },
  {
    image: surfersParadise,
    title: "Surfers Paradise",
    description: "Oil & Acrylic on board. 58cm X 58cm. 2015",
    alt: "Surfers Paradise. Oil & Acrylic on board. 58cm X 58cm. 2015",
  },
  {
    image: twentyNineDegrees,
    title: "Twenty nine degrees",
    description: "Oil & Acrylic on board. 58cm X 58cm. 2015",
    alt: "Twenty nine degrees. Oil & Acrylic on board. 58cm X 58cm. 2015",
  },
  {
    image: weirdTogether,
    title: "Weird Together",
    description: "Oil & Acrylic on board. 58cm X 58cm. 2015",
    alt: "Weird Together. Oil & Acrylic on board. 58cm X 58cm. 2015",
  },
  {
    image: theRehearsal,
    title: "The Rehearsal",
    description: "Oil & Acrylic on board. 100cm X 120cm. 2015",
    alt: "The Rehearsal. Oil & Acrylic on board. 100cm X 120cm. 2015",
  },
  {
    image: kidsAtClovelly,
    title: "Kids at Clovelly",
    description: "Oil & Acrylic on board. 60cm X 60cm. 2016",
    alt: "Kids at Clovelly. Oil & Acrylic on board. 60cm X 60cm. 2016",
  },
  {
    image: aDayInPaddo,
    title: "A Day in Paddo",
    description: "Oil & Acrylic on linen. 61cm X 61cm. 2017",
    alt: "A Day in Paddo. Oil & Acrylic on linen. 61cm X 61cm. 2017",
  },
  {
    image: businessTripToHK,
    title: "Business Trip to Hong Kong",
    description: "Oil & Acrylic on board. 60cm X 60cm. 2016",
    alt: "Business Trip to Hong Kong. Oil & Acrylic on board. 60cm X 60cm. 2016",
  },
  {
    image: bondiSummers,
    title: "Bondi Summers",
    description: "Oil & Acrylic on canvas. 150cm X 150cm. 2015",
    alt: "Bondi Summers. Oil & Acrylic on canvas. 150cm X 150cm. 2015",
  },
  {
    image: sydney,
    title: "Sydney",
    description: "Oil & Acrylic on canvas. 150cm X 150cm. 2015",
    alt: "Sydney. Oil & Acrylic on canvas. 150cm X 150cm. 2015",
  },
  {
    image: bondiSummers2,
    title: "Bondi Summers II",
    description: "Oil & Acrylic on canvas. 140cm x 150cm. 2016",
    alt: "Bondi Summers II. Oil & Acrylic on canvas. 140cm x 150cm. 2016",
  },
  {
    image: sydney2,
    title: "Sydney II",
    description: "Oil & Acrylic on canvas. 140cm x 150cm. 2015",
    alt: "Sydney II. Oil & Acrylic on canvas. 140cm x 150cm. 2015",
  },
  {
    image: theLongWeekend,
    title: "The Long Weekend",
    description: "Oil & Acrylic on board. 58cm X 58cm. 2015",
    alt: "The Long Weekend. Oil & Acrylic on board. 58cm X 58cm. 2015",
  },
  {
    image: saltInTheirEyes,
    title: "Salt in their eyes",
    description: "Oil & Acrylic on board. 58cm X 58cm. 2015",
    alt: "Salt in their eyes. Oil & Acrylic on board. 58cm X 58cm. 2015",
  },
  {
    image: canaryWharf,
    title: "Canary Wharf",
    description: "Oil & Acrylic on board. 58cm X 58cm. 2014",
    alt: "Canary Wharf. Oil & Acrylic on board. 58cm X 58cm. 2014",
  },
  {
    image: takapunaBeach,
    title: "Drinking Hop Zombies at Takapuna Beach",
    description: "Oil & Acrylic on board. 58cm X 58cm. 2015",
    alt: "Drinking Hop Zombies at Takapuna Beach. Oil & Acrylic on board. 58cm X 58cm. 2015",
  },
  {
    image: coffeeBreak,
    title: "Coffee Break",
    description: "Oil & Acrylic on board. 58cm X 58cm. 2015",
    alt: "Coffee Break. Oil & Acrylic on board. 58cm X 58cm. 2015",
  },
  {
    image: frankiesBikinis,
    title: "Frankies Bikinis",
    description: "Oil & Acrylic on board. 58cm X 58cm. 2015",
    alt: "Frankies Bikinis. Oil & Acrylic on board. 58cm X 58cm. 2015",
  },
  {
    image: theOpeningReception,
    title: "The Opening Reception",
    description: "Oil & Acrylic on board. 58cm X 58cm. 2015",
    alt: "The Opening Reception. Oil & Acrylic on board. 58cm X 58cm. 2015",
  },
  {
    image: touchRugbyTamarama,
    title: "Touch Rugby at Tamarama",
    description: "Oil & acrylic on board. 60cm x 60cm. 2016",
    alt: "Touch Rugby at Tamarama. Oil & acrylic on board. 60cm x 60cm. 2016",
  },
  {
    image: saturdayAfternoon,
    title: "Saturday Afternoon",
    description: "Oil & acrylic on board. 60cm x 60cm. 2016",
    alt: "Saturday Afternoon. Oil & acrylic on board. 60cm x 60cm. 2016",
  },
  {
    image: paddingtonStudio,
    title: "Paddington Studio",
    description: "Sydney 2016",
    alt: "Paddington studio- Sydney 2016",
  },
  {
    image: saintCloche,
    title: "2017 Solo Show",
    description: "Bankers Lawyers and Beach Bums at Saint Cloche- Sydney 2017",
    alt: "Bankers Lawyers and Beach Bums at Saint Cloche- Sydney 2017",
  },
  {
    image: pinkAfternoon,
    title: "Pink Afternoon",
    description: "Oil & acrylic on canvas. 140cm x 150cm. 2016",
    alt: "Pink Afternoon. Oil & acrylic on canvas. 140cm x 150cm. 2016",
  },
  {
    image: unicornsAtBondi,
    title: "Unicorns at Bondi",
    description: "Oil & acrylic on board. 60cm x 60cm. 2016",
    alt: "Unicorns at Bondi. Oil & acrylic on board. 60cm x 60cm. 2016",
  },
  {
    image: boxingDayTestMatch,
    title: "Boxing Day Test Match",
    description: "Oil & acrylic on linen. 50cm x 60cm. 2016",
    alt: "Boxing Day Test Match. Oil & acrylic on linen. 50cm x 60cm. 2016",
  },
  {
    image: bondiNippers,
    title: "Bondi Nippers",
    description: "Oil & acrylic on linen. 41cm x 36cm. 2016",
    alt: "Bondi Nippers. Oil & acrylic on linen. 41cm x 36cm. 2016",
  },
  {
    image: fedSquare,
    title: "Federation Square- Melbourne",
    description: "Oil & acrylic on canvas. 137cm x 152cm. 2017",
    alt: "Federation Square- Melbourne. Oil & acrylic on canvas. 137cm x 152cm. 2017",
  },
  {
    image: northBondi2,
    title: "North Bondi II",
    description: "Oil & acrylic on linen. 56cm x 66cm. 2017",
    alt: "North Bondi II. Oil & acrylic on linen. 56cm x 66cm. 2017",
  },
  {
    image: milkBeach2,
    title: "Milk Beach II",
    description: "Oil & acrylic on linen. 61cm x 61cm. 2017",
    alt: "Milk Beach II. Oil & acrylic on linen. 61cm x 61cm. 2017",
  },
  {
    image: chinamansBeach,
    title: "Chinamans Beach",
    description: "Oil & acrylic on linen. 61cm x 61cm. 2017",
    alt: "Chinamans Beach. Oil & acrylic on linen. 61cm x 61cm. 2017",
  },
  {
    image: northBondi3,
    title: "North Bondi III",
    description: "Oil & acrylic on linen. 61cm x 61cm. 2017",
    alt: "North Bondi III. Oil & acrylic on linen. 61cm x 61cm. 2017",
  },
  {
    image: pinkAfernoon3,
    title: "Pink Afternoon III",
    description: "Oil & acrylic on linen. 91cm x 91cm. 2017",
    alt: "Pink Afternoon III. Oil & acrylic on linen. 91cm x 91cm. 2017",
  },
  {
    image: twoDegrees,
    title: "2 Degrees of Separation",
    description: "Oil on board. 48cm x 53cm. 2009",
    alt: "2 Degrees of Separation. Oil on board. 48cm x 53cm. 2009",
  },
  {
    image: longBlack,
    title: "Long Black",
    description: "Oil on canvas. 150cm x 200cm. 2008",
    alt: "Long Black. Oil on canvas. 150cm x 200cm. 2008",
  },
  {
    image: people108,
    title: "108 People",
    description: "Oil on canvas. 65cm x 80cm. 2008",
    alt: "108 People. Oil on canvas. 65cm x 80cm. 2008",
  },
  {
    image: studio14,
    title: "Studio 14",
    description: "Oil on canvas. 150cm x 200cm. 2007",
    alt: "Studio 14. Oil on canvas. 150cm x 200cm. 2007",
  },
  {
    image: officeParty,
    title: "Office Party",
    description: "Oil on canvas. 65cm x 80cm. 2007",
    alt: "Office Party. Oil on canvas. 65cm x 80cm. 2007",
  },
  {
    image: mrWhippy,
    title: "Mr Whippy",
    description: "Oil on canvas. 150cm x 200cm. 2007",
    alt: "Mr Whippy. Oil on canvas. 150cm x 200cm. 2007",
  },
  {
    image: soloShow2016,
    title: "2017 Solo Show",
    description: "Bankers Lawyers and Beach Bums at Saint Cloche- Sydney 2017",
    alt: "Bankers Lawyers and Beach Bums at Saint Cloche- Sydney 2017",
  },
  {
    image: southPiha,
    title: "South Piha",
    description: "Oil & acrylic on canvas. 65cm x 80cm. 2008",
    alt: "South Piha. Oil & acrylic on canvas. 65cm x 80cm. 2008",
  },
  {
    image: timesSquare,
    title: "ForFive Coffee Shop - Times Square NYC",
    description: "117 W 46th St, New York",
    alt: "ForFive Coffee Shop - Times Square NYC. 117 W 46th St, New York",
  },
  {
    image: afternoonYogaClass,
    title: "Afternoon Yoga Class",
    description: "Oil & Acrylic on board. 58cm X 58cm. 2015",
    alt: "Afternoon Yoga Class. Oil & Acrylic on board. 58cm X 58cm. 2015",
  },
];

export default peopleScapesOldCollection;
