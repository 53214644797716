import i1 from "../../images/shop/22ndprintTheOptimist2/1_theOptimist2.jpg";
import i2 from "../../images/shop/22ndprintTheOptimist2/2_theOptimist2.JPG";
import i3 from "../../images/shop/22ndprintTheOptimist2/3_theOptimist2.jpg";
import i4 from "../../images/shop/22ndprintTheOptimist2/4_theOptimist2.jpg";
import i5 from "../../images/shop/22ndprintTheOptimist2/5_theOptimist2.jpg";
import i6 from "../../images/shop/22ndprintTheOptimist2/6_theOptimist2.jpg";
import i7 from "../../images/shop/22ndprintTheOptimist2/7_theOptimist2.jpg";

const theOptimist2Collection = [
  { image: i1, alt: "The Optimist II" },
  { image: i2, alt: "The Optimist II" },
  { image: i3, alt: "The Optimist II" },
  { image: i4, alt: "The Optimist II" },
  { image: i5, alt: "The Optimist II" },
  { image: i6, alt: "The Optimist II" },
  { image: i7, alt: "The Optimist II" },
];

export default theOptimist2Collection;
