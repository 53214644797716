import React from "react";
import instaLogo from "../icons/Instagram.svg";
import faceLogo from "../icons/facebook.svg";

function Contact() {
  const openInNewWindow = theLink => {
    window.open(theLink);
  };

  return (
    <div className='iconContainer'>
      <div className='iconBox'>
         <img
          className='icon'
          src={instaLogo}
          alt="Instagram Logo, click to go to Tyrone's Instagram!"
          onClick={() =>
            openInNewWindow("https://www.instagram.com/tyronelayne/")
          }
        />
        <img
          className='icon'
          src={faceLogo}
          alt="Facebook logo, click to go to Tyrone's Facebook!"
          onClick={() =>
            openInNewWindow("https://www.facebook.com/tyrone.layne.artist/")
          }
        />
      </div>
    </div>
  );
}

export default Contact;
