import youngForever from "../../images/Paintings/misc/1_youngForever.jpg";
import upInTheClouds from "../../images/Paintings/misc/2_upInTheClouds.jpg";
import heaven3 from "../../images/Paintings/misc/3_heaven3.jpg";
import heaven2 from "../../images/Paintings/misc/4_heaven2.jpg";
import heavenProgress from "../../images/Paintings/misc/5_heavenProgress.jpg";
import heaven from "../../images/Paintings/misc/6_heaven.jpg";
import hell from "../../images/Paintings/misc/7_hell.jpg";
import hellArtFair from "../../images/Paintings/misc/8_hellArtFair.jpg";
import hellDetail from "../../images/Paintings/misc/9_hellDetail.jpg";
import untitleda from "../../images/Paintings/misc/10_untitled.jpg";
import untitledb from "../../images/Paintings/misc/11_untitled.jpg";
import ihumanDatabase from "../../images/Paintings/misc/12_humanDatabase.jpg";
import jhumanDatabase from "../../images/Paintings/misc/13_humanDatabase.jpg";
import khumanDatabase from "../../images/Paintings/misc/14_humanDatabase.jpg";
import the14thStreetStation from "../../images/Paintings/misc/15_14thStreetStation.jpg";
import inNOutBurger from "../../images/Paintings/misc/16_inNOutBurger.jpg";
import yieldFarmers from "../../images/Paintings/misc/17_yieldFarmers.jpg";
import theWoods from "../../images/Paintings/misc/18_theWoods.jpg";
import ahumanDatabase2 from "../../images/Paintings/misc/19_humanDatabase2.jpg";
import bhumanDatabase2 from "../../images/Paintings/misc/20_humanDatabase2.jpg";
import chumanDatabase2 from "../../images/Paintings/misc/21_humanDatabase2.jpg";
import dhumanDatabase2 from "../../images/Paintings/misc/22_humanDatabase2.jpg";
import ehumanDatabase2 from "../../images/Paintings/misc/23_humanDatabase2.jpg";
import fhumanDatabase2 from "../../images/Paintings/misc/24_humanDatabase2.jpg";
import ghumanDatabase2 from "../../images/Paintings/misc/25_humanDatabase2.jpg";
import hhumanDatabase2 from "../../images/Paintings/misc/26_humanDatabase2.jpg";

const miscCollection = [
  {
    image: youngForever,
    title: "Young Forever",
    description: "Oil on canvas. 130cm x 220cm. 2009",
    alt: "Young Forever. Oil on canvas. 130cm x 220cm. 2009",
  },
  {
    image: upInTheClouds,
    title: "Up in the clouds",
    description: "Oil & acrylic on canvas. 90cm x 70cm. 2009",
    alt: "Up in the clouds. Oil & acrylic on canvas. 90cm x 70cm. 2009",
  },
  {
    image: heaven3,
    title: "Heaven III",
    description: "Oil on linen. 36cm X 41cm. 2019",
    alt: "Heaven III. Oil on linen. 36cm X 41cm. 2019",
  },
  {
    image: heaven2,
    title: "Heaven II",
    description: "Oil on linen. 36cm X 41cm. 2019",
    alt: "Heaven II. Oil on linen. 36cm X 41cm. 2019",
  },
  {
    image: heavenProgress,
    title: "Heaven (progress)",
    description: "Oil on linen. (Diptych) 198cm X 304cm. 2019",
    alt: "Heaven. Oil on linen. (Diptych) 198cm X 304cm. 2019",
  },
  {
    image: heaven,
    title: "Heaven",
    description: "Oil on linen. (Diptych) 198cm X 304cm. 2019",
    alt: "Heaven. Oil on linen. (Diptych) 198cm X 304cm. 2019",
  },
  {
    image: hell,
    title: "Hell",
    description: "Oil on linen. 152cm X 183cm. 2019",
    alt: "Hell. Oil on linen. 152cm X 183cm. 2019",
  },
  {
    image: hellArtFair,
    title: "Hell(Auckland Art Fair)",
    description: "Oil on linen. 152cm X 183cm. 2019",
    alt: "Hell. Oil on linen. 152cm X 183cm. 2019",
  },
  {
    image: hellDetail,
    title: "Hell(Detail)",
    description: "Oil on linen. 152cm X 183cm. 2019",
    alt: "Hell. Oil on linen. 152cm X 183cm. 2019",
  },
  {
    image: untitleda,
    title: "Untitled",
    description: "Oil on board. 20cm X 20cm. 2022",
    alt: "Untitled. Oil on board. 20cm X 20cm. 2022",
  },
  {
    image: untitledb,
    title: "Untitled",
    description: "Oil on board. 20cm X 20cm. 2022",
    alt: "Untitled. Oil on board. 20cm X 20cm. 2022",
  },
  {
    image: ihumanDatabase,
    title: "Human Database-NFT collection blueprints",
    description: "Oil on board. 25cm X 25cm. 2021",
    alt: "Human Database. Oil on board. 25cm X 25cm. 2021",
  },
  {
    image: jhumanDatabase,
    title: "Human Database-NFT collection blueprints",
    description: "Oil on board. 25cm X 25cm. 2021",
    alt: "Human Database. Oil on board. 25cm X 25cm. 2021",
  },
  {
    image: khumanDatabase,
    title: "Human Database-NFT collection blueprints",
    description: "Oil on board. 25cm X 25cm. 2021",
    alt: "Human Database. Oil on board. 25cm X 25cm. 2021",
  },
  {
    image: the14thStreetStation,
    title: "14th Street Station (1/1 NFT)",
    description: "Oil on board + digital drawing. 2022",
    alt: "14th Street Station (1/1 NFT). Oil on board + digital drawing. 2022",
  },
  {
    image: inNOutBurger,
    title: "In-N-Out Burger (1/1 NFT)",
    description: "Oil on board + digital drawing. 2022",
    alt: "In-N-Out Burger (1/1 NFT). Oil on board + digital drawing. 2022",
  },
  {
    image: yieldFarmers,
    title: "Yield Farmers (1/1 NFT)",
    description: "Oil on board + digital drawing. 2022",
    alt: "Yield Farmers (1/1 NFT). Oil on board + digital drawing. 2022",
  },
  {
    image: theWoods,
    title: "The Woods (1/1 NFT)",
    description: "Oil on board + digital drawing. 2022",
    alt: "The Woods (1/1 NFT). Oil on board + digital drawing. 2022",
  },
  {
    image: ahumanDatabase2,
    title: "Human Database 2-NFT collection",
    description:
      "Digitally hand drawn traits generating 3200 different characters. 2022",
    alt: "Human Database 2- NFTs",
  },
  {
    image: bhumanDatabase2,
    title: "Human Database 2-NFT collection",
    description:
      "Digitally hand drawn traits generating 3200 different characters. 2022",
    alt: "Human Database 2- NFTs",
  },
  {
    image: chumanDatabase2,
    title: "Human Database 2-NFT collection",
    description:
      "Digitally hand drawn traits generating 3200 different characters. 2022",
    alt: "Human Database 2- NFTs",
  },
  {
    image: dhumanDatabase2,
    title: "Human Database 2-NFT collection",
    description:
      "Digitally hand drawn traits generating 3200 different characters. 2022",
    alt: "Human Database 2- NFTs",
  },
  {
    image: ehumanDatabase2,
    title: "Human Database 2-NFT collection",
    description:
      "Digitally hand drawn traits generating 3200 different characters. 2022",
    alt: "Human Database 2- NFTs",
  },
  {
    image: fhumanDatabase2,
    title: "Human Database 2-NFT collection",
    description:
      "Digitally hand drawn traits generating 3200 different characters. 2022",
    alt: "Human Database 2- NFTs",
  },
  {
    image: ghumanDatabase2,
    title: "Human Database 2-NFT collection",
    description:
      "Digitally hand drawn traits generating 3200 different characters. 2022",
    alt: "Human Database 2- NFTs",
  },
  {
    image: hhumanDatabase2,
    title: "Human Database 2-NFT collection",
    description:
      "Digitally hand drawn traits generating 3200 different characters. 2022",
    alt: "Human Database 2- NFTs",
  },
];

export default miscCollection;
