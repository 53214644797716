// import blank from "../images/blank.png";
import i1 from "../images/recent/1TOAFLA2022.jpg";
import i2 from "../images/recent/2Cityofangels.jpg";
import i3 from "../images/recent/3Cityofangelsdetail.jpg";
import i4 from "../images/recent/4Strangeparadise3.jpg";
import i5 from "../images/recent/5Strangeparadise3detail.jpg";
import i6 from "../images/recent/6Woollahra2.jpg";
import i7 from "../images/recent/7Woollahr2detials.jpg";
import i8 from "../images/recent/8paddo2.jpg";
import i9 from "../images/recent/9theDinnerParty.jpg";
import i10 from "../images/recent/10newYorkersSummer.jpg";
import i11 from "../images/recent/11campcove.jpg";
import i12 from "../images/recent/12familyDinner.jpg";
import i13 from "../images/recent/13busStop5.jpg";
import i14 from "../images/recent/14ottawa.jpg";
import i15 from "../images/recent/15soloshow2016.jpg";
import i16 from "../images/recent/16studioNov2023.jpg";
import i17 from "../images/recent/17therestaurant.jpg";
import i18 from "../images/recent/18therestaurantdetail.jpg";
import i19 from "../images/recent/19redfern.jpg";
import i20 from "../images/recent/20manly3.jpg";
import i21 from "../images/recent/21skiapres.jpg";
import i22 from "../images/recent/22parisminseries.jpg";
import i23 from "../images/recent/23tribecaminiseries.jpg";
import i24 from "../images/recent/24inflatablelips.jpg";
import i25 from "../images/recent/25bondisummers5.jpg";
import i26 from "../images/recent/26toafBK2023.jpg";
import i27 from "../images/recent/27theinterview6.jpg";
import i28 from "../images/recent/28surryhills.jpg";
import i29 from "../images/recent/29paddington7.jpg";
import i30 from "../images/recent/30juneNewYork.jpg";
import i31 from "../images/recent/31inflatablehulk.jpg";
import i32 from "../images/recent/32jacksonSquare.jpg";
import i33 from "../images/recent/33newYorkersWinter.jpg";
import i34 from "../images/recent/34greeneSt.jpg";
import i35 from "../images/recent/35strangeParadise4.jpg";
import i36 from "../images/recent/36bondisummers7.jpg";
import i37 from "../images/recent/37thredbo4.jpg";
import i38 from "../images/recent/38soho2053.jpg";
import i39 from "../images/recent/39paddington5.jpg";
import i40 from "../images/recent/40kuttibeach2.jpg";
import i41 from "../images/recent/41soloshow2017.jpg";
import i42 from "../images/recent/42theoptimist.jpg";
import i43 from "../images/recent/43october.jpg";

/*
imageCollection is an [array,array,array] of {objects:objects,objects:objects}, structured as follows:
{
  image: importedImage
  title:"Mona Lisa"
  description: "Oil on Canvas, 2017 etc",
  alt:"Painting of a woman with an ambiguous smile, with a bleak landscape behind her",
  sold: true,
  price: "6300"

}
image must be imported from it's relative location at the top of this script.
title and description will display beneath each image,
alt is for screen readers for visually impaired people, put a more detailed description here.
sold is optional, true will add a little red "-SOLD" next to the title
price is optional, will add a price to the label
*/

const imageCollection = [
  // { image: blank, alt: "blank" },

  {
    image: i1,
    title: "The Other Art Fair (Booth installation)",

    description: "Santa Monica, Los Angeles Sep 22nd-25th 2022",
    alt: "The Other Art Fair- Santa Monica, Los Angeles, Sep 22nd-25th 2022 (Booth installation)",
  },
  {
    image: i2,
    title: "City of Angels",
    description: "Oil & acrylic on linen. 150cm x 150cm. 2022",
    alt: "City of Angels. Oil & acrylic on linen. 150cm x 150cm. 2022.",
  },
  {
    image: i3,
    title: "City of Angels (detail)",

    alt: "City of Angels- detail",
  },
  {
    image: i4,
    title: "Strange Paradise III",

    description: "Oil & acrylic on linen. 150cm x 150cm. 2022",
    alt: "Strange Paradise III. Oil & acrylic on linen. 150cm x 150cm. 2022",
  },
  {
    image: i5,
    title: "Strange Paradise III (detail)",
    alt: "Strange Paradise III (detail)",
  },
  {
    image: i6,
    title: "A Day in Woollahra II",
    description: "Oil & acrylic on linen. 76cm x 76cm. 2022",
    alt: "A Day in Woollahra II. Oil & acrylic on linen. 76cm x 76cm. 2022. ",
  },
  {
    image: i7,
    title: "A Day in Woollahra II (detail)",
    alt: "A Day in Woollahra II (detail)",
  },
  {
    image: i8,
    title: "Paddington II",
    description: "Oil & acrylic on canvas. 140cm x 150cm. 2016",
    alt: "Paddington II. Oil & acrylic on canvas. 140cm x 150cm. 2016.",
  },
  {
    image: i9,
    title: "The Dinner Party",
    description: "Oil on linen. 45.5cm x 45.5cm. 2022-23",
    alt: "The Dinner Party. 45.5cm x 45.5cm. 2022-23.",
  },
  {
    image: i10,
    title: "New Yorkers- Summer",
    description: "Oil & acrylic on linen. 152cm x 152cm. 2023",
    alt: "New Yorkers- Summer. Oil & acrylic on linen. 152cm x 152cm. 2023. ",
  },
  {
    image: i11,
    title: "Camp Cove IV & Camp Cove IV (15 minutes later)",
    description: "Oil & acrylic on linen. 61cm x 71cm. 2021",
    alt: "Camp Cove IV & Camp Cove IV (15 minutes later). Oil & acrylic on linen. 61cm x 71cm. 2021.",
  },
  {
    image: i12,
    title: "Family Dinner",
    description: "Oil on linen. 76cm x 76cm. 2023",
    alt: "Family Dinner. Oil on linen. 76cm x 76cm. 2023.",
  },
  {
    image: i13,
    title: "The Bus Stop V: La Nina Summer",
    description: "Oil & acrylic on linen. 152cm x 183cm. 2023",
    alt: "The Bus Stop V: La Nina Summer. Oil & acrylic on linen. 152cm x 183cm. 2023.",
  },
  {
    image: i14,
    title: "Rideau Canal, Ottawa",
    description: "Oil & acrylic on linen. 137cm x 152cm. 2021",
    alt: "Rideau Canal, Ottawa. Oil & acrylic on linen. 137cm x 152cm. 2021.",
  },
  {
    image: i15,
    title: "Solo Show: Lawyers, Bankers, & Beach Bums",
    description: "Saint Cloche, Sydney, May 2016",
    alt: "Solo show- Lawyers, Bankers, & Beach Bums at Saint Cloche, Sydney, May 2016.",
  },
  {
    image: i16,
    title: "Sydney Studio- November 2023",
    description: "Recent paintings for TOAF in Brooklyn",
    alt: "Sydney Studio- November 2023. Recent paintings for TOAF in Brooklyn.",
  },
  {
    image: i17,
    title: "The Restaurant",
    description: "Oil & acrylic on linen. 61cm x 71cm. 2022",
    alt: "The Restaurant. Oil & acrylic on linen. 61cm x 71cm. 2022.",
  },
  {
    image: i18,
    title: "The Restaurant (detail)",
    alt: "The Restaurant- detail",
  },
  {
    image: i19,
    title: "East Redfern",
    description: "Oil & acrylic on linen. 61cm x 71cm. 2021",
    alt: "East Redfern. Oil & acrylic on linen. 61cm x 71cm. 2021.",
  },
  {
    image: i20,
    title: "Manly III",
    description: "Oil & acrylic on linen. 137cm x 152cm. 2021",
    alt: "Manly III. Oil & acrylic on linen. 137cm x 152cm. 2021.",
  },
  {
    image: i21,
    title: "Après-ski",
    description: "Oil & acrylic on linen. 30.5cm x 30.5cm. 2022",
    alt: "Après-ski. Oil & acrylic on linen. 30.5cm x 30.5cm. 2022.",
  },
  {
    image: i22,
    title: "Feeding Time",
    description: "Oil on linen. 22.5cm x 20cm. 2021",
    alt: "Feeding Time. Oil on linen. 22.5cm x 20cm. 2021.",
  },
  {
    image: i23,
    title: "Crying Child",
    description: "Oil on linen. 22.5cm x 20cm. 2021",
    alt: "Crying Child. Oil on linen. 22.5cm x 20cm. 2021.",
  },
  {
    image: i24,
    title: "Inflatable Lips and a Popsicle",
    description: "Oil & acrylic on linen. 76cm x 76cm. 2022",
    alt: "Inflatable Lips and a Popsicle. Oil & acrylic on linen. 76cm x 76cm. 2022.",
  },
  {
    image: i25,
    title: "Bondi Summers V",
    description: "Oil & acrylic on canvas. 140cm x 150cm. 2016",
    alt: "Bondi Summers V. Oil & acrylic on canvas. 140cm x 150cm. 2016.",
  },
  {
    image: i26,
    title: "The Other Art Fair- Brooklyn",
    description: "Brooklyn Navy Yard, May 18th-21st 2023.",
    alt: "The Other Art Fair- Brooklyn. Brooklyn Navy Yard, May 2023",
  },
  {
    image: i27,
    title: "The Interview VI",
    description: "Oil & acrylic on linen. 84cm x 84cm. 2019",
    alt: "The Interview VI. Oil & acrylic on linen. 84cm x 84cm. 2019.",
  },
  {
    image: i28,
    title: "Surry Hills",
    description: "Oil & acrylic on linen. 137cm x 152cm. 2021",
    alt: "Surry Hills. Oil & acrylic on linen. 137cm x 152cm. 2021.",
  },
  {
    image: i29,
    title: "Paddington VII",
    description: "Oil & acrylic on linen. 137cm x 152cm. 2021",
    alt: "Paddington VII. Oil & acrylic on linen. 137cm x 152cm. 2021.",
  },
  {
    image: i30,
    title: "Summer Morning, New York",
    description: "Oil & acrylic on linen. 152cm x 152cm. 2023",
    alt: "Summer Morning, New York. Oil & acrylic on linen. 152cm x 152cm. 2023.",
  },
  {
    image: i31,
    title: "Pegasus & Hulk at the Beach",
    description: "Oil & acrylic on canvas. 140cm x 150cm. 2016",
    alt: "Pegasus & Hulk at the Beach. Oil & acrylic on canvas. 140cm x 150cm. 2016.",
  },
  {
    image: i32,
    title: "Jackson Square, San Francisco",
    description: "Oil & acrylic on linen. 91cm x 86cm. 2023",
    alt: "Jackson Square, San Francisco. Oil & acrylic on linen. 91cm x 86cm. 2023.",
  },
  {
    image: i33,
    title: "New Yorkers- Winter",
    description: "Oil & acrylic on linen. 152cm x 152cm. 2023",
    alt: "New Yorkers- Winter. Oil & acrylic on linen. 152cm x 152cm. 2023.",
  },
  {
    image: i34,
    title: "Greene St",
    description: "Oil & acrylic on linen. 41cm x 36cm. 2023",
    alt: "Greene St. Oil & acrylic on linen. 41cm x 36cm. 2023.",
  },
  {
    image: i35,
    title: "Strange Paradise IV",
    description: "Oil & acrylic on linen. 152cm x 152cm. 2023",
    alt: "Strange Paradise IV. Oil & acrylic on linen. 152cm x 152cm. 2023.",
  },
  {
    image: i36,
    title: "Bondi Summers VII",
    description: "Oil & acrylic on linen. 137cm x 152cm. 2020",
    alt: "Bondi Summers VII. Oil & acrylic on linen. 137cm x 152cm. 2020.",
  },
  {
    image: i37,
    title: "Thredbo IV",
    description: "Oil & acrylic on linen. 137cm x 152cm. 2023",
    alt: "Thredbo IV. Oil & acrylic on linen. 137cm x 152cm. 2023.",
  },
  {
    image: i38,
    title: "Soho 2053",
    description: "Oil & acrylic on linen. 152cm x 152cm. 2023",
    alt: "Soho 2053. Oil & acrylic on linen. 152cm x 152cm. 2023",
  },
  {
    image: i39,
    title: "Paddington V",
    description: "Oil & acrylic on linen. 137cm x 152cm. 2020",
    alt: "Paddington V. Oil & acrylic on linen. 137cm x 152cm. 2020.",
  },
  {
    image: i40,
    title: "Kutti Beach II",
    description: "Oil & acrylic on linen. 61cm x 71cm. 2021",
    alt: "Kutti Beach II. Oil & acrylic on linen. 61cm x 71cm. 2021.",
  },
  {
    image: i41,
    title: "Solo Show: Eastern Suburbs",
    description: "Saint Cloche, Sydney, 2017",
    alt: "Solo Show- Eastern Suburbs at Saint Cloche, Sydney, 2017",
  },
  {
    image: i42,
    title: "The Optimist",
    description: "Limited edition prints available in the online shop",
    alt: "The Optimist- Limited Edition print available in the online shop.",
  },
  {
    image: i43,
    title: "October",
    description: "Oil & acrylic on linen. 150cm x 150cm. 2022",
    alt: "October. Oil & acrylic on linen. 150cm x 150cm. 2022.",
  },
  // { image: blank, alt: "blank" },
];

export default imageCollection;
