import React from "react";
import { Helmet } from "react-helmet-async";
import ImageFormatted from "./../components/ImageFormatted";
import Image from "./../components/Image";
import Title from "../components/Title";
import BioInfo from "../components/BioInfo.js";
import Exhibitions from "../components/Exhibitions";

import artistPicture from "../images/bio/Tyronelayne_studio_dec2020.jpg";
import artistPortrait from "../images/bio/tyronelayneportrait.jpg";
import easternSuburbs from "../images/bio/easternsuburbs_install.jpeg";
import studio from "../images/bio/studio20211.jfif";

import soloExhibitions from "./../data/soloExhibitions_Data";
import groupExhibitions from "./../data/groupExhibitions_Data";
import Contact from "../components/Contact";
import FloatingSubtitle from "./../components/FloatingSubtitle";
import BackButton from "../components/BackButton";

function Biography() {
  return (
    <div>
      <Helmet>
        <title>Tyrone Layne | About</title>
        <meta property='og.title' content='Tyrone Layne | About' />
        <meta property='og.image' content={studio} />
        <meta
          property='og.description'
          content="Tyrone's oil on canvas peoplescapes are fresh and sensitively drawn observations 
              of human behaviour, layered with intricate exchanges between characters in the background 
              whilst making confidently broad statements about people and culture - documenting the 
              ways of the people in his newfound home."
        />
        <link rel='canonical' href='http://tyronelayne.com/biography' />
      </Helmet>
      <Title subtitle='about' />
      <FloatingSubtitle text='biography' extraCSS='aboutTitle' />
      {/* <div className='aboutSpacer' /> */}
      <ImageFormatted
        source={artistPicture}
        description='Tyrone Layne, painting in his studio.'
        cssClass='tyroneStudio'
        topImage={true}
      />
      <Contact />
      <BioInfo />
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ width: "33rem", maxWidth: "95vw", fontSize: "1.1rem" }}>
          <p style={{ color: "darkgray", textAlign: "left" }}>
            Artist Statement - Peoplescapes
          </p>
          <p style={{ textAlign: "left" }}>
          It can be said that my “Peoplescape” painting scenes are a reflection of my personal observations and understanding of the world around me. By harnessing the collective energy of a certain place, city or time, I am able to convey the cultural trends and emotions that I have experienced. I take inspiration from the various media sources and moments that I absorb in my day-to-day living, such as news, literature, television, and social media.
          </p>
          <p style={{ textAlign: "left" }}>
          By combining these inspirations with my own personal observations, I am able to create a unique perspective in my art. Additionally, by highlighting certain mundane details that are often overlooked, I aim to bring attention to the beauty and significance of the everyday, and to challenge the viewer's perception of the world around them.
          </p>
        </div>
      </div>
      <ImageFormatted
        source={artistPortrait}
        description={
          "Tyrone Layne standing in front of a door with 'part-time artist' in graffiti."
        }
        cssClass='tyronePortrait'
      />
      <h2>Solo Exhibitions:</h2>
      <Exhibitions exhibitions={soloExhibitions} />
      <Image
        source={easternSuburbs}
        description='Eastern suburbs exhibition installation.'
        cssClass='eastPic'
      />
      <br />
      <h2>Selected Group Exhibitions & Art Fairs:</h2>
      <Exhibitions exhibitions={groupExhibitions} />
      <Image
        source={studio}
        description='Picture of the studio'
        cssClass='studioPic'
      />
      <br />
      <BackButton />
    </div>
  );
}

export default Biography;
