import { scroller } from "react-scroll";

export default function imageScroller(cssClass) {
  scroller.scrollTo(cssClass, {
    duration: 100,
    delay: 0,
    smooth: "linear",
    offset: -50,
  });
}
