import React from "react";

function Square({ thePrice, theTitle, theLink }) {
  return (
    <div className='squareBox'>
      <div>Secure payment via Square</div>
      <br />
      <div className='square'>
        <div>{theTitle}</div>
        <div className='price'>{thePrice}</div>
        <a
          className='squareButton'
          target='_blank'
          rel='noreferrer'
          href={theLink}
        >
          Buy Now
        </a>
      </div>
    </div>
  );
}

export default Square;
