import React from "react";
import Square from "../../../components/Square";
import SquareSpacer from "../../../components/SquareSpacer";
import hollywoodCollection from "../../../data/shop/5thPrintHollywood_Data";
import HeaderSpacer from "../../../components/HeaderSpacer";
import BackButton from "./../../../components/BackButton";
import SlideShow from "../../../components/SlideShow";
import { Helmet } from "react-helmet-async";

function Hollywood() {
  return (
    <div>
      <Helmet>
        <title>TL | Parenting in the Hollywood Hills</title>
        <meta property='og.title' content='Parenting in the Hollywood Hills' />
        <meta property='og.image' content={hollywoodCollection[0].image} />
      </Helmet>
      <HeaderSpacer />

      <p>
        <strong>Parenting in the Hollywood Hills - 2020.</strong>
      </p>
      <strong>
        <p>Limited Edition of 50 prints + 4 A.P's.</p>
      </strong>
      <p>
        Giclee archival pigment print on 310 gsm 100% cotton rag fine art
        archival paper.
      </p>
      <p>Print Size 40cm x 35cm / 15.75in x 13.78in.</p>
      <p>Paper Size 48cm x 43cm / 18.90in x 16.93in.</p>
      <p>
        Signed and Numbered on the front bottom right of paper, and on the
        reverse side of paper.
      </p>
      <p>
        <strong>$155 AUD each </strong>- Unframed.
      </p>
      <p>Includes FREE postage within Australia only.</p>
      <p>$10 shipping to NZ & $20 anywhere else in the world.</p>
      <p>Item is shipped rolled up in a tube via Australia Post.</p>
      <p>Allow 3-5 working days before shipment is dispatched.</p>
      <p>
        (Shipping Delays due to COVID-19. Especially for outside of Australia.)
      </p>

      <SlideShow
        slides={hollywoodCollection}
        slideShowName='hollywood'
        startingIndex={0}
        slideHeight='slideShort'
        scrollOffset={-70}
        shopSlideShow={true}
      />
      <Square
        thePrice='$155.00 - $175.00'
        theTitle='Parenting in the Hollywood Hills'
        theLink='https://checkout.square.site/merchant/ML0ASPD4MWN81/checkout/F5AQE6CWAY2Y4Z5IHAQRNAHP?src=embed'
      />
      <SquareSpacer />
      <BackButton />
    </div>
  );
}

export default Hollywood;
