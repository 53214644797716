import fifteen from "../../images/Paintings/Abstracts/01_fifteen.jpg";
import twentyThree from "../../images/Paintings/Abstracts/02_twentyThree.jpg";
import twentyNine from "../../images/Paintings/Abstracts/03_twentyNine.jpg";
import thirtyOne from "../../images/Paintings/Abstracts/04_thirtyOne.jpg";
import thirtyTwo from "../../images/Paintings/Abstracts/05_thirtyTwo.jpg";
import thirtyFour from "../../images/Paintings/Abstracts/37_thirtyFour.jpg";
import thirtyFive from "../../images/Paintings/Abstracts/38_thirtyFive.jpg";
import twentyFour from "../../images/Paintings/Abstracts/06_twentyFour.jpg";
import twentyEight from "../../images/Paintings/Abstracts/07_twentyEight.jpg";
import thirtyStudio from "../../images/Paintings/Abstracts/08_thirtyStudio.jpg";
import thirtyInstall from "../../images/Paintings/Abstracts/09_thirtyInstall.jpg";
import twentySeven from "../../images/Paintings/Abstracts/10_twentySeven.jpg";
import thirteen from "../../images/Paintings/Abstracts/11_thirteen.jpg";
import install from "../../images/Paintings/Abstracts/12_install.jpg";
import twenty from "../../images/Paintings/Abstracts/13_twenty.jpg";
import twentyFive from "../../images/Paintings/Abstracts/14_twentyFive.jpg";
import twentySix from "../../images/Paintings/Abstracts/15_twentySix.jpg";
import nineteen from "../../images/Paintings/Abstracts/16_nineteen.jpg";
import untitled1 from "../../images/Paintings/Abstracts/17_untitled.jpg";
import sixteen from "../../images/Paintings/Abstracts/18_sixteen.jpg";
import seventeen from "../../images/Paintings/Abstracts/19_seventeen.jpg";
import fourteen from "../../images/Paintings/Abstracts/20_fourteen.jpg";
import untitled2 from "../../images/Paintings/Abstracts/21_untitled.jpg";
import twentyOne from "../../images/Paintings/Abstracts/42_twentyOne.jpg";
import digitalPollution from "../../images/Paintings/Abstracts/22_digitalPollution.jpg";
import dystopiaOpening from "../../images/Paintings/Abstracts/23_dystopiaOpening.jpg";
import reasonableDoubt from "../../images/Paintings/Abstracts/24_reasonableDoubt.jpg";
import nightCrawler from "../../images/Paintings/Abstracts/25_nightCrawler.jpg";
import donutHoles from "../../images/Paintings/Abstracts/26_donutHoles.jpg";
import newLane from "../../images/Paintings/Abstracts/27_newLane.JPG";
import untitled3 from "../../images/Paintings/Abstracts/39_untitleds.jpg";
import purpleHaze from "../../images/Paintings/Abstracts/28_purpleHaze.jpg";
import purpleHaze2 from "../../images/Paintings/Abstracts/29_purpleHaze2.jpg";
import paradise from "../../images/Paintings/Abstracts/30_paradise.jpg";
import greenThoughts from "../../images/Paintings/Abstracts/40_greenThoughts.jpg";
import untitled4 from "../../images/Paintings/Abstracts/41_untitled.jpg";
import tropicalStorm from "../../images/Paintings/Abstracts/31_tropicalStorm.jpg";
import alexTheKid from "../../images/Paintings/Abstracts/32_alexkidd.jpg";
import toafLA from "../../images/Paintings/Abstracts/33_toafLA.jpg";
import eight from "../../images/Paintings/Abstracts/34_eight.jpg";
import seven from "../../images/Paintings/Abstracts/35_seven.jpg";
import two from "../../images/Paintings/Abstracts/36_two.jpg";

const abstractCollection = [
  {
    image: fifteen,
    title: "Fifteen",
    description: "Oil on linen. 198cm X 304cm (Diptych). 2019",
    alt: "Fifteen. Oil on linen. 198cm X 304cm (Diptych). 2019",
  },
  {
    image: twentyThree,
    title: "Twenty Three",
    description: "Oil on canvas. 152cm X 137cm. 2020",
    alt: "Twenty Three. Oil on canvas. 152cm X 137cm. 2020",
  },
  {
    image: twentyNine,
    title: "Twenty Nine",
    description: "Oil on canvas. 152cm X 183cm. 2021",
    alt: "Twenty Nine. Oil on canvas. 152cm X 183cm. 2021",
  },
  {
    image: thirtyOne,
    title: "Thirty One",
    description: "Oil on canvas. 152cm X 183cm. 2021-2022",
    alt: "Thirty One. Oil on canvas. 152cm X 183cm. 2021-2022",
  },
  {
    image: thirtyTwo,
    title: "Thirty Two",
    description: "Oil on canvas. 137cm X 152cm. 2022",
    alt: "Thirty Two. Oil on canvas. 137cm X 152cm. 2022",
  },
  {
    image: thirtyFour,
    title: "Thirty Four",
    description: "Oil on canvas. 152cm X 152cm. 2023",
    alt: "Thirty Four. Oil on canvas. 152cm X 152cm. 2023",
  },
  {
    image: thirtyFive,
    title: "Thirty Five",
    description: "Oil on canvas. 152cm X 152cm. 2023",
    alt: "Thirty Five. Oil on canvas. 152cm X 152cm. 2023",
  },
  {
    image: twentyFour,
    title: "Twenty Four",
    description: "Oil on canvas. 122cm X 152cm. 2020",
    alt: "Twenty Four. Oil on canvas. 122cm X 152cm. 2020",
  },
  {
    image: twentyEight,
    title: "Twenty Eight",
    description: "Oil on canvas. 183cm X 152cm. 2021",
    alt: "Twenty Eight. Oil on canvas. 183cm X 152cm. 2021",
  },
  {
    image: thirtyStudio,
    title: "Thirty and Untitled's",
    description: "Oil on canvas. 152cm x 122cm. 2021",
    alt: "Thirty. Oil on canvas. 152cm x 122cm. 2021",
  },
  {
    image: thirtyInstall,
    title: "Thirty",
    description: "Oil on canvas. 152cm x 122cm. 2021",
    alt: "Thirty. Oil on canvas. 152cm x 122cm. 2021",
  },
  {
    image: twentySeven,
    title: "Twenty Seven",
    description: "Oil on canvas. 137cm X 152cm. 2021",
    alt: "Twenty Seven. Oil on canvas. 137cm X 152cm. 2021",
  },
  {
    image: thirteen,
    title: "Thirteen",
    description: "Oil on linen. 137cm X 152cm. 2019",
    alt: "Thirteen. Oil on linen. 137cm X 152cm. 2019",
  },
  {
    image: install,
    title: "Twenty & Twenty Two",
    description: "Oil on canvas. 2020",
    alt: "Twenty & Twenty Two",
  },
  {
    image: twenty,
    title: "Twenty",
    description: "Oil on canvas. 122cm X 152cm. 2019-2020",
    alt: "Twenty. Oil on canvas. 122cm X 152cm. 2019-2020",
  },
  {
    image: twentyFive,
    title: "Twenty Five",
    description: "Oil on canvas. 152cm X 183cm. 2020",
    alt: "Twenty Five. Oil on canvas. 152cm X 183cm. 2020",
  },
  {
    image: twentySix,
    title: "Twenty Six",
    description: "Oil on canvas. 122cm X 152cm. 2020",
    alt: "Twenty Six. Oil on canvas. 122cm X 152cm. 2020",
  },
  {
    image: nineteen,
    title: "Nineteen",
    description: "Oil on canvas. 122cm X 152cm. 2019",
    alt: "Nineteen. Oil on canvas. 122cm X 152cm. 2019",
  },
  {
    image: untitled1,
    title: "Untitled",
    description: "Oil on linen. 91cm X 102cm. 2019",
    alt: "Untitled. Oil on linen. 91cm X 102cm. 2019",
  },
  {
    image: sixteen,
    title: "Sixteen",
    description: "Oil on linen. 152cm X 183cm. 2019",
    alt: "Sixteen. Oil on linen. 152cm X 183cm. 2019",
  },
  {
    image: seventeen,
    title: "Seventeen",
    description: "Oil on canvas. 152cm X 137cm. 2019",
    alt: "Seventeen. Oil on canvas. 152cm X 137cm. 2019",
  },
  {
    image: fourteen,
    title: "Fourteen",
    description: "Oil on linen. 137cm X 152cm. 2019",
    alt: "Fourteen. Oil on linen. 137cm X 152cm. 2019",
  },
  {
    image: untitled3,
    title: "Untitled & Cadmium Monster",
    description: "Oil on linen. 41cm X 36cm. 2019",
    alt: "Untitled & Cadmium Monster. Oil on linen. 41cm X 36cm. 2019",
  },
  {
    image: untitled2,
    title: "Untitled",
    description: "Oil on linen. 31cm X 41cm. 2019",
    alt: "Untitled. Oil on linen. 31cm X 41cm. 2019",
  },
  {
    image: twentyOne,
    title: "Twenty One",
    description: "Oil on canvas. 152cm x 122cm. 2020",
    alt: "Twenty One. Oil on canvas. 152cm x 122cm. 2020",
  },
  {
    image: digitalPollution,
    title: "Digital Pollution- Day",
    description: "Oil on canvas. 40cm X 35cm. 2018",
    alt: "Digital Pollution- Day. Oil on canvas. 40cm X 35cm. 2018",
  },
  {
    image: dystopiaOpening,
    title: "DYSTOPIA",
    description: "at DePlume, Los Angeles, 26th January - 2nd March, 2019",
    alt: "DYSTOPIA at DePlume, Los Angeles, 26th January - 2nd March, 2019",
  },
  {
    image: reasonableDoubt,
    title: "Reasonable Doubt",
    description: "Oil on canvas. 152cm X 137cm. 2018",
    alt: "Reasonable Doubt. Oil on canvas. 152cm X 137cm. 2018",
  },
  {
    image: nightCrawler,
    title: "Night Crawler",
    description: "Oil on linen. 41cm X 36cm. 2018",
    alt: "Night Crawler. Oil on linen. 41cm X 36cm. 2018",
  },
  {
    image: donutHoles,
    title: "Donut Holes",
    description: "Oil on canvas. 102cm x 91cm. 2019",
    alt: "Donut Holes. Oil on canvas. 102cm x 91cm. 2019",
  },
  {
    image: newLane,
    title: "New Lane",
    description: "Oil on canvas. 152cm x 137cm. 2019",
    alt: "New Lane. Oil on canvas. 152cm x 137cm. 2019",
  },
  {
    image: purpleHaze,
    title: "Purple Haze",
    description: "Oil on linen. 41cm X 36cm. 2018",
    alt: "Purple Haze. Oil on linen. 41cm X 36cm. 2018",
  },
  {
    image: purpleHaze2,
    title: "Purple Haze II",
    description: "Oil on canvas. 102cm X 91cm. 2019",
    alt: "Purple Haze II. Oil on canvas. 102cm X 91cm. 2019",
  },
  {
    image: paradise,
    title: "Paradise",
    description: "Oil on canvas. 102cm X 91cm. 2018",
    alt: "Paradise. Oil on canvas. 102cm X 91cm. 2018",
  },
  {
    image: greenThoughts,
    title: "Green Thoughts",
    description: "Oil on canvas. 102cm X 91cm. 2019",
    alt: "Green Thoughts. Oil on canvas. 102cm X 91cm. 2019",
  },
  {
    image: untitled4,
    title: "Untitled",
    description: "Oil on linen. 41cm X 36cm. 2018",
    alt: "Untitled. Oil on linen. 41cm X 36cm. 2018",
  },
  {
    image: tropicalStorm,
    title: "Tropical Storm",
    description: "Oil on canvas. 71cm X 61cm. 2018",
    alt: "Tropical Storm. Oil on canvas. 71cm X 61cm. 2018",
  },
  {
    image: alexTheKid,
    title: "Alex Kidd- Grown Up",
    description: "Oil on canvas. 137cm x 152cm. 2018",
    alt: "Alex Kidd- Grown Up. Oil on canvas. 137cm x 152cm. 2018",
  },
  {
    image: toafLA,
    title: "Four & Eight",
    description:
      "Oil on canvas- The Other Art Fair- Downtown Los Angeles, March 2018",
    alt: "Four & Eight, Oil on canvas- The Other Art Fair- Downtown Los Angeles, March 2018",
  },
  {
    image: eight,
    title: "Eight",
    description: "Oil on canvas. 183cm x 152cm. 2018",
    alt: "Eight. Oil on canvas. 183cm x 152cm. 2018",
  },
  {
    image: seven,
    title: "Seven",
    description: "Oil on canvas. 183cm X 152cm. 2018",
    alt: "Seven. Oil on canvas. 183cm X 152cm. 2018",
  },
  {
    image: two,
    title: "Two",
    description: "Oil on canvas. 183cm X 152cm. 2017",
    alt: "Two. Oil on canvas. 183cm X 152cm. 2017",
  },
];
export default abstractCollection;
