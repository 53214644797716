import i1 from "../../images/shop/5thprintHollywood/1_hollywoodHills.jpg";
import i2 from "../../images/shop/5thprintHollywood/2_hollywoodHills.jpg";
import i3 from "../../images/shop/5thprintHollywood/3_hollywoodHills.jpg";
import i4 from "../../images/shop/5thprintHollywood/4_hollywoodHills.jpg";
import i5 from "../../images/shop/5thprintHollywood/5_hollywoodHills.jpg";
import i6 from "../../images/shop/5thprintHollywood/6_hollywoodHills.jpg";
import i7 from "../../images/shop/5thprintHollywood/7_hollywoodHills.jpg";

/*
imageCollection is an [array,array,array] of {objects:objects,objects:objects}, structured as follows:
{
  image: importedImage
  title:"Mona Lisa"
  description: "Oil on Canvas, 2017 etc",
  alt:"Painting of a woman with an ambiguous smile, with a bleak landscape behind her",
  sold: true,
  price: "6300"

}
image must be imported from it's relative location at the top of this script.
title and descimport imageCollection from './homeSlideShow';
ription will display beneath each image,
alt is for screen readers for visually impaired people, put a more detailed description here.
sold is optional, true will add a little red "-SOLD" next to the title
price is optional, will add a price to the label
*/

const hollywoodCollection = [
  {
    image: i1,
    alt: "Hollywood Hills",
  },
  {
    image: i2,
    alt: "Hollywood Hills",
  },
  {
    image: i3,
    alt: "Hollywood Hills",
  },
  {
    image: i4,
    alt: "Hollywood Hills",
  },
  {
    image: i5,
    alt: "Hollywood Hills",
  },
  {
    image: i6,
    alt: "Hollywood Hills",
  },
  {
    image: i7,
    alt: "Hollywood Hills",
  },
];

export default hollywoodCollection;
