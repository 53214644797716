/*
example of a full exhibition object:
{
    year: "2022 Exhibition Schedule",
    exhibition: "Solo booth 'Peoplescapes' The Other Art Fair, ",
    location: "The Barker Hangar, Santa Monica, CA USA",
    date: "22nd-25th September 2022",
    link: { theLink: "/bio", linkText: "Here is the Link" },
    linkOut: { theLink: "http://www.google.com", linkText: "Link to google" },
  }

  note that for a link out you must include the full url including http:// or https://
*/

const upcomingExhibitions = [
  {
    year: "2024 Exhibition Schedule",
    exhibition: "Solo booth - The Other Art Fair",
    location: "ZeroSpace, 337-345 Butler St, Brooklyn USA",
    date: "16th - 19th May 2024",
  },
  {
    year: "2023 Exhibition Schedule",
    exhibition: "Solo Booth - The Other Art Fair",
    location: "ZeroSpace, 337-345 Butler St, Brooklyn USA",
    date: "9th - 12th November 2023",
  },
  {
    exhibition: "Art Market San Francisco, Maune Contemporary",
    location: "Fort Mason Center, 2 Marina Blvd, San Francisco USA",
    date: "20th - 23rd April 2023",
  },
  {
    exhibition: "Solo Booth - The Other Art Fair",
    location: "Brooklyn Navy Yard, Agger Fish, Brooklyn USA",
    date: "18th - 21st May 2023",
  },
 ];

export default upcomingExhibitions;
